import { ClickupTask, NewTask, Task } from "../types/task";
import api from "../utils/api";

export const getTasks = async (projectId: number): Promise<Task[]> => {
  const response = await api.get(`/clickup/projects/${projectId}/tasks`);
  const clickupTasks: ClickupTask[] = response.data;
  return clickupTasks.map((clickupTask: ClickupTask) => {
    return {
      id: clickupTask.clickUpId,
      name: clickupTask.name,
      status: clickupTask.status,
    };
  });
};

export const createTask = async (
  newTask: NewTask,
  projectId: number
): Promise<Task> => {
  const response = await api.post(
    `/clickup/projects/${projectId}/tasks`,
    newTask
  );
  const clickupTask: ClickupTask = response.data;
  return {
    id: clickupTask.clickUpId,
    name: clickupTask.name,
    status: clickupTask.status,
  };
};
