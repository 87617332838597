import React, { Component } from "react";
import { getProjects } from "../../controller/nextCloudPhotosController.js";
import { Client } from "../../types/client.js";
import { Project } from "../../types/project.js";
import MultiSelectDropdown from "../base/multiselect.js";

interface Props {
  name: string;
  client: Client;
  selectedProject?: Project;
  selectedProjects?: Project[];
  onChangeMultiple?: (projects: Project[]) => void;
  onChangeSingle?: (project: Project) => void;
  multiple?: boolean;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  reloadTrigger?: number;
}

interface State {
  projects: Project[];
}

class PhotosProjectDropdown extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      projects: [],
    };
    this.onChange = this.onChange.bind(this);
    this.loadProjects = this.loadProjects.bind(this);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.reloadTrigger !== this.props.reloadTrigger) {
      this.setState({ projects: [] });
      this.loadProjects();
    }
  }

  async loadProjects() {
    const projects = await getProjects(this.props.client);
    this.setState({ projects });
    return projects;
  }

  onChange(name: string, projects: Project[]) {
    if (this.props.onChangeMultiple) {
      this.props.onChangeMultiple(projects);
    }
    if (this.props.onChangeSingle) {
      this.props.onChangeSingle(projects[0]);
    }
  }

  render() {
    return (
      <MultiSelectDropdown<Project>
        name={this.props.name}
        textField="name"
        loadItems={this.loadProjects.bind(this)}
        options={this.state.projects}
        onChange={this.onChange}
        selectedItems={
          this.props.selectedProjects ?? [this.props.selectedProject]
        }
        required={this.props.required}
        multiple={this.props.multiple}
        disabled={this.props.disabled}
      />
    );
  }
}

export default PhotosProjectDropdown;
