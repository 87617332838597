import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormCheck,
  FormControl,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import z from "zod";
import { createProject } from "../../../controller/projectController";
import { getAllWorkTypes } from "../../../controller/tmetricController";
import { NewProjectType, Project } from "../../../types/project";
import { WorkType } from "../../../types/tmetric";
import LoadingButton from "../../base/LoadingButton";
import { useTimeEntryStore } from "../context/TimeEntryContext";

const schema = z.object({
  projectName: z.string().min(1),
  projectDistance: z.string().min(1),
  billable: z.boolean().optional(),
});

interface Props {
  show: boolean;
  onClose: () => void;
  onSuccess: (project: Project) => void;
}

const CreateProjectModal: React.FC<Props> = ({ show, onClose, onSuccess }) => {
  const { selectedClient } = useTimeEntryStore();
  const { register, handleSubmit, reset } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  const queryClient = useQueryClient();

  const [workTypes, setWorkTypes] = useState<WorkType[]>([]);
  const [selectedWorkTypeIds, setSelectedWorkTypeIds] = useState<string[]>([]);

  const mutation = useMutation(
    (newProject: NewProjectType) => createProject(newProject),
    {
      onSuccess: (project: Project) => {
        queryClient.invalidateQueries(["projects"]);
        console.log(project);
        onSuccess(project);
        onClose();
        reset();
      },
      onError: (error: any) => {
        toast.error(error.message);
      },
    }
  );

  const handleWorkTypeChange = (workTypeId: string, isChecked: boolean) => {
    setSelectedWorkTypeIds((prevIds) => {
      if (isChecked && !prevIds.includes(workTypeId)) {
        return [...prevIds, workTypeId];
      } else if (!isChecked && prevIds.includes(workTypeId)) {
        return prevIds.filter((id) => id !== workTypeId);
      }
      return prevIds;
    });
  };

  useEffect(() => {
    async function fetchWorkTypes() {
      const fetchedWorkTypes = await getAllWorkTypes();
      setWorkTypes(fetchedWorkTypes);
    }
    fetchWorkTypes();
  }, []);

  const onSubmit = async (data: any) => {
    const { projectName, projectDistance, billable } = data;

    // Convert selected work type IDs to the desired format
    const formattedWorkTypes = selectedWorkTypeIds.map((id) => ({
      workTypeId: id,
    }));

    if (!(projectName && projectDistance)) {
      return;
    }

    mutation.mutate({
      name: projectName,
      clickUpClientId: String(selectedClient.id),
      distance: parseInt(projectDistance),
      isBillable: billable,
      workTypes: formattedWorkTypes,
    });
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create Project</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Name (Format: KAV - Mill)</Form.Label>
            <InputGroup>
              <FormControl
                placeholder="Project Name"
                {...register("projectName", { required: true })}
              />
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Distance</Form.Label>
            <InputGroup className="d-flex align-items-center justify-content-center">
              <FormControl
                type="number"
                placeholder="Project distance"
                {...register("projectDistance", { required: true })}
              />
              <InputGroup.Text>km</InputGroup.Text>
              <div style={{ marginLeft: "1rem" }}>
                <FormCheck
                  label="Billable"
                  type="checkbox"
                  id="billable"
                  {...register("billable")}
                />
              </div>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Work Types</Form.Label>
            <InputGroup className="d-flex align-items-center justify-content-center">
              {workTypes.map((workType) => (
                <div
                  style={{ marginLeft: "1rem", marginRight: "1rem" }}
                  key={workType.id}
                >
                  <FormCheck
                    label={workType.name}
                    type="checkbox"
                    checked={selectedWorkTypeIds.includes(
                      workType.id.toString()
                    )}
                    onChange={(e) =>
                      handleWorkTypeChange(
                        workType.id.toString(),
                        e.target.checked
                      )
                    }
                  />
                </div>
              ))}
            </InputGroup>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" isLoading={mutation.isLoading}>
            Submit
          </LoadingButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateProjectModal;
