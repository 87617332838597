import { create } from "zustand";
import { Client } from "../../../types/client";
import { Project } from "../../../types/project";
import { Task } from "../../../types/task";
import { LocationType, WorkType } from "../../../types/tmetric";

type State = {
  selectedClient: Client;
  selectedProject: Project;
  selectedTask: Task;
  selectedWorkType: WorkType;
  selectedLocation: LocationType;
  notes: string;
  showCreateClientModal: boolean;
  showCreateProjectModal: boolean;
  showCreateTaskModal: boolean;
  showCreateWorkTypeModal: boolean;
  showCreateLocationModal: boolean;
};

const initialState: State = {
  selectedClient: undefined,
  selectedProject: undefined,
  selectedTask: undefined,
  selectedWorkType: undefined,
  selectedLocation: undefined,
  notes: "",
  showCreateClientModal: false,
  showCreateProjectModal: false,
  showCreateTaskModal: false,
  showCreateWorkTypeModal: false,
  showCreateLocationModal: false,
};

type Store = State & {
  setClient: (client: Client) => void;
  setProject: (project: Project) => void;
  setTask: (task: Task) => void;
  setWorkType: (workType: WorkType) => void;
  setLocation: (location: LocationType) => void;
  setNotes: (notes: string) => void;
  setShowCreateClientModal: (show: boolean) => void;
  setShowCreateProjectModal: (show: boolean) => void;
  setShowCreateTaskModal: (show: boolean) => void;
  setShowCreateWorkTypeModal: (show: boolean) => void;
  setShowCreateLocationModal: (show: boolean) => void;
};

export const useTimeEntryStore = create<Store>((set) => ({
  ...initialState,
  setClient: (client) => set({ selectedClient: client }),
  setProject: (project) => set({ selectedProject: project }),
  setTask: (task) => set({ selectedTask: task }),
  setWorkType: (workType) => set({ selectedWorkType: workType }),
  setLocation: (location) => set({ selectedLocation: location }),
  setNotes: (notes) => set({ notes }),
  setShowCreateClientModal: (show) => set({ showCreateClientModal: show }),
  setShowCreateProjectModal: (show) => set({ showCreateProjectModal: show }),
  setShowCreateTaskModal: (show) => set({ showCreateTaskModal: show }),
  setShowCreateWorkTypeModal: (show) => set({ showCreateWorkTypeModal: show }),
  setShowCreateLocationModal: (show) => set({ showCreateLocationModal: show }),
}));
