import moment from "moment";
import { create } from "zustand";
import {
  TmetricTimeEntriesResponseType,
  TmetricTimerResponseStarted,
  TmetricTimerResponseStopped,
} from "../../../types/tmetric";

type State = {
  timeEntries: TmetricTimeEntriesResponseType[];
  timer: TmetricTimerResponseStarted | TmetricTimerResponseStopped | undefined;
  date: string;
  totalHours: number;
  timeEntriesLoading: boolean;
  timerLoading: boolean;
  loading: boolean;
  reloadTrigger: boolean;
  setTimeEntries: (entries: TmetricTimeEntriesResponseType[]) => void;
  setDate: (date: string) => void;
  setTotalHours: (hours: number) => void;
  setTimeEntriesLoading: (loading: boolean) => void;
  setTimerLoading: (loading: boolean) => void;
  setLoading: (loading: boolean) => void;
  setTimer: (
    timer: TmetricTimerResponseStarted | TmetricTimerResponseStopped | undefined
  ) => void;
  reload: () => void;
  setDateToday: () => void;
};

export const useTimeDisplayStore = create<State>((set) => ({
  timeEntries: [],
  date: moment().format("YYYY-MM-DDT00:00:00"),
  totalHours: 0,
  timeEntriesLoading: false,
  timerLoading: false,
  loading: false,
  timer: undefined,
  reloadTrigger: false,
  setTimeEntries: (entries) => set({ timeEntries: entries }),
  setDate: (date) => set({ date }),
  setTotalHours: (hours) => set({ totalHours: hours }),
  setTimeEntriesLoading: (loading) => set({ timeEntriesLoading: loading }),
  setTimerLoading: (loading) => set({ timerLoading: loading }),
  setLoading: (loading) => set({ loading }),
  setTimer: (timer) => set({ timer }),
  reload: () => set((state) => ({ reloadTrigger: !state.reloadTrigger })),
  setDateToday: () => set({ date: moment().format("YYYY-MM-DDT00:00:00") }),
}));
