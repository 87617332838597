import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import z from "zod";
import { createTask } from "../../../controller/taskController";
import { NewTask, Task } from "../../../types/task";
import { getMemberId } from "../../../utils/util";
import LoadingButton from "../../base/LoadingButton";
import { useTimeEntryStore } from "../context/TimeEntryContext";

const schema = z.object({
  taskName: z.string().min(1),
  taskDescription: z.string().min(1),
});

interface Props {
  show: boolean;
  onClose: () => void;
  onSuccess: (task: Task) => void;
}

export default function CreateTaskModal({ show, onClose, onSuccess }: Props) {
  const { selectedProject, selectedClient } = useTimeEntryStore();
  const { register, handleSubmit } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newTask: NewTask) => createTask(newTask, selectedProject.id),
    {
      onSuccess: (task: Task) => {
        queryClient.invalidateQueries(["tasks"]);
        onSuccess(task);
        onClose();
      },
      onError: (error: any) => {
        toast.error(error.message);
      },
    }
  );

  const onSubmit = async (data: z.infer<typeof schema>) => {
    const newTask: NewTask = {
      tMetricMemberId: getMemberId(),
      name: data.taskName,
      description: data.taskDescription,
    };

    mutation.mutate(newTask);
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Task</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Form.Group className="col-6">
              <Form.Label>Client</Form.Label>
              <Form.Control
                type="text"
                value={selectedClient?.name}
                readOnly
                disabled
              />
            </Form.Group>
            <Form.Group className="col-6">
              <Form.Label>Project</Form.Label>
              <Form.Control
                type="text"
                value={selectedProject?.name}
                readOnly
                disabled
              />
            </Form.Group>
          </Row>

          <Row className="mt-3">
            <Form.Group controlId="taskName" className="col-12">
              <Form.Label className="">Task Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter task name"
                {...register("taskName")}
              />
            </Form.Group>

            <Form.Group controlId="taskDescription" className="col-12">
              <Form.Label className="">Task Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter task description"
                {...register("taskDescription")}
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton isLoading={mutation.isLoading} type="submit">
            Create
          </LoadingButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
