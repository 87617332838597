import { useEffect } from "react";
import { getTimer } from "../../../controller/tmetricController";
import { useTimeDisplayStore } from "../context/TimeDisplayContext";


export function useFetchTimer() {
  const { date, reloadTrigger, setTimerLoading, setTimer } = useTimeDisplayStore();

  useEffect(() => {
    async function fetchTimer() {
      setTimerLoading(true);
      const timer = await getTimer();
      setTimer(timer);
      setTimerLoading(false);
    }

    fetchTimer();
  }, [date, reloadTrigger, setTimer, setTimerLoading]);
}
