import React from "react";
import { FormLabel, InputGroup } from "react-bootstrap";
import { WorkType } from "../../../types/tmetric";
import { useTimeEntryStore } from "../context/TimeEntryContext";
import WorkTypeDropdown from "../dropdowns/WorkTypeDropdown";

export default function WorkTypeInput() {
  const { selectedWorkType, selectedProject, setWorkType } =
    useTimeEntryStore();

  const onSelectWorkType = (name: string, workType: WorkType) => {
    setWorkType(workType);
  };

  return (
    <>
      <FormLabel>Work Type</FormLabel>
      <InputGroup>
        <WorkTypeDropdown
          className="col-12 col-md-6 col-lg-4"
          projectId={selectedProject?.id}
          name={"workType"}
          disabled={selectedProject ? false : true}
          selectedWorkType={selectedWorkType}
          onChangeSingle={onSelectWorkType}
        />
      </InputGroup>
    </>
  );
}
