import React from "react";
import { FormCheck, Row } from "react-bootstrap";
import EditModal, { BaseProps, BaseState } from "../base/editModal.js";
import FormInput from "../base/formInput.js";
import { InventoryItem } from "./inventoryItemType.js";

const URL = "/inventoryItems";

interface Props extends BaseProps<InventoryItem> {}

interface State extends BaseState<InventoryItem> {}

const defaultInventoryItem: InventoryItem = {
  name: "",
  description: "",
  quantity: 0,
  isActive: true,
  salesPrice: 0,
  purchaseCost: 0,
  averageCost: 0,
  quantityOnOrder: 0,
  quantityOnSalesOrder: 0,
};

class EditInventoryItemModal extends EditModal<InventoryItem, Props, State> {
  constructor(props: Props) {
    super(props);
    const InventoryItem = {
      ...defaultInventoryItem,
      ...props.initialValues,
    };

    this.state = {
      modalTitle: InventoryItem.id
        ? "Edit Inventory Item"
        : "Add Inventory Item",
      item: InventoryItem,
      itemId: InventoryItem.id ?? 0,
      loading: !!InventoryItem.id,
      error: null,
      isNewItem: !InventoryItem.id,
      url: URL,
      validated: false,
    };
  }

  renderFields = () => {
    const { item } = this.state;
    return (
      <div className="col">
        <Row>
          <div className="col-12">
            <Row>
              <FormInput
                className="col-12 col-md-4"
                name="name"
                label="Name"
                type="text"
                value={item.name}
                onChange={this.handleChange}
                required
              />

              <FormInput
                className="col-12 col-md-8"
                name="description"
                label="Description"
                type="text"
                value={item.description}
                onChange={this.handleChange}
                required
              />

              <FormInput
                className="col-12 col-md-4"
                name="purchaseCost"
                label="Purchase Cost"
                type="number"
                value={item.purchaseCost}
                onChange={this.handleChange}
                prefix="$"
                required
              />

              <FormInput
                className="col-12 col-md-4"
                name="salesPrice"
                label="Sales Price"
                type="number"
                value={item.salesPrice}
                onChange={this.handleChange}
                prefix="$"
                required
              />

              <FormInput
                className="col-12 col-md-4"
                name="quantity"
                label="Quantity on Hand"
                type="number"
                value={item.quantity}
                onChange={this.handleChange}
                disabled={!this.state.isNewItem}
                required
              />

              <FormCheck
                className="col-12 col-md-4"
                name="isActive"
                label="Active"
                type="checkbox"
                checked={item.isActive}
                onChange={this.handleChange}
              />
            </Row>
          </div>
        </Row>
      </div>
    );
  };
}

export default EditInventoryItemModal;
