import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import api from '../utils/api.js';

interface Props {
    handleLogin: (token: string, username: string) => void;
    handleForgotPassword: () => void;
}

const LoginPage: React.FC<Props> = ({ handleLogin, handleForgotPassword }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onLoginClick = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = {
            username,
            password,
        };

        try {
            const response = await api.post('/users/local_signin', data);
            const { access_token } = response.data;
            localStorage.removeItem('token');
            handleLogin(access_token, username);
        } catch (error) {
            setError(true);
            setErrorMessage('Invalid username or password');
        }
    };


    return (
        <div className="container d-flex align-items-center justify-content-centers">
            <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-5 align-items-center justify-content-center d-flex flex-column text-center">
                    <h1>Insert cringy catchphrase here</h1>
                    <Image
                        src='/logo512.png'
                        className='img-fluid'
                        alt='logo'
                        style={{ width: '150px', height: '150px', objectFit: 'contain' }}
                    />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-5">
                    <Form onSubmit={onLoginClick}>
                        {error && <Alert variant='danger'>{errorMessage}</Alert>}
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <Form.Group className="form-outline mb-4 w-100">
                                <Form.Label className="form-label">Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="form-outline mb-3 w-100">
                                <Form.Label className="form-label">Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Form.Group>
                            <div className="d-flex justify-content-between align-items-center w-100">
                                <a href="#!" className="text-body"
                                    onClick={() => handleForgotPassword()}
                                >Forgot password?</a>
                            </div>
                            <div className="d-flex justify-content-between align items-center w-100 pt-3">
                                <Button variant='primary' type='submit' className='btn btn-primary btn-block w-100'>Login</Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
