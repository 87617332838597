import api from "../utils/api";

interface User {
    username: string;
    email: string;
    tmetricId: string | null;
    userprofileId: string | null;
    roles: string[];
    tables: string[];
}

export const getTmetricId = async (username: string): Promise<string> => {
    const response = await api.get("/users");
    const users: User[] = response.data;
    const user = users.find((user: any) => user.username === username);
    if (user) {
        return user.tmetricId;
    } else {
        throw new Error("User not found");
    }
}

export const getUserprofileId = async (username: string): Promise<string> => {
    const response = await api.get("/users");
    const users: User[] = response.data;
    const user = users.find((user: any) => user.username === username);
    if (user) {
        return user.userprofileId;
    } else {
        throw new Error("User not found");
    }
}


export const forgotPassword = async (email: string): Promise<void> => {
    await api.post("/users/forgot_password", { email });
    return Promise.resolve();
}