import React, {Component, ElementType} from 'react';
import {Form, InputGroup} from 'react-bootstrap';

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  value?: number | string;
  units?: string;
  prefix?: string;
  type?: string;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  isValid?: boolean;
  max?: any;
  min?: any;
  className?: string;
  as?: ElementType<any>;
  onChange?:(event: React.ChangeEvent<any>) => void;
}

interface State {}

class FormInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {name, type, label, placeholder, onChange, value, units,
      required, disabled, hidden, className, prefix, isValid, max, min} = this.props;

    return (
      <Form.Group className={className} hidden={hidden}>
        {label && (<Form.Label>{label}</Form.Label>)}
        <InputGroup>
          { prefix && (
            <InputGroup.Text className='m-0'>{prefix}</InputGroup.Text>
          )
          }
          <Form.Control
            as={this.props.as}
            type={type}
            name={name}
            placeholder={placeholder}
            isValid={isValid}
            value={value == null ? '' : value}
            onChange={onChange}
            required={required}
            disabled={disabled}
            max={max}
            min={min}
            locale='YYYY-MM-DD'
          />
          { units && (
            <InputGroup.Text>{units}</InputGroup.Text>
          )
          }
        </InputGroup>
      </Form.Group>
    );
  }
}

export default FormInput;
