import React, { Component } from "react";
import { Client, Project, Result } from "../../types/invoicingModel.js";
import MultiSelectDropdownDropdown from "../base/multiselect.js";

interface Props {
  selectedClient: Client;
  name: string;
  selectedProject?: Project;
  selectedProjects?: Project[];
  onChangeMultiple?: (name: string, projects: Project[]) => void;
  onChangeSingle?: (name: string, project: Project) => void;
  multiple?: boolean;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  clientId?: number;
  reloadTrigger?: boolean;
  result: Result;
}

interface State {
  projects: Project[];
}

class InvoicingProjectDropdown extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      projects: [],
    };
    this.onChange = this.onChange.bind(this);
    this.loadProjects = this.loadProjects.bind(this);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.clientId !== this.props.clientId) {
      this.loadProjects();
    }
    if (prevProps.reloadTrigger !== this.props.reloadTrigger) {
      this.loadProjects();
    }
    if (prevProps.result !== this.props.result) {
      this.loadProjects();
    }
  }

  componentDidMount(): void {
    this.loadProjects();
  }

  async loadProjects() {
    if (!this.props.result) return;
    if (!this.props.selectedClient) return;
    const projects = this.props.selectedClient.projects;
    projects.map((project) => {
      project.billableRate = project.billableRate
        ? Math.round(project.billableRate * 100) / 100
        : 0;
    });
    this.setState({ projects: projects });
  }

  onChange(name: string, projects: Project[]) {
    if (this.props.onChangeMultiple) {
      this.props.onChangeMultiple(name, projects);
    }
    if (this.props.onChangeSingle) {
      this.props.onChangeSingle(name, projects[0]);
    }
  }

  render() {
    return (
      <MultiSelectDropdownDropdown<Project>
        name={this.props.name}
        textField="name"
        subTextField="billableRate"
        loadItems={this.loadProjects.bind(this)}
        options={this.state.projects}
        onChange={this.onChange}
        selectedItems={
          this.props.selectedProjects ?? [this.props.selectedProject]
        }
        required={this.props.required}
        multiple={this.props.multiple}
        disabled={this.props.disabled}
      />
    );
  }
}

export default InvoicingProjectDropdown;
