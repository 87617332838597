import React from "react";
import { FormLabel, InputGroup } from "react-bootstrap";
import { LocationType } from "../../../types/tmetric";
import { useTimeEntryStore } from "../context/TimeEntryContext";
import LocationDropdown from "../dropdowns/LocationDropdown";



export default function LocationInput() {
    const { selectedLocation, selectedProject, setLocation } = useTimeEntryStore();

    const onSelectLocation = (name: string, location: LocationType) => {
        setLocation(location);
    }

    return (
        <>
            <FormLabel>Location</FormLabel>
            <InputGroup>
                <LocationDropdown
                    className="col-12 col-md-6 col-lg-4"
                    name={"location"}
                    disabled={selectedProject ? false : true}
                    selectedLocation={selectedLocation}
                    onChangeSingle={onSelectLocation}
                />
            </InputGroup>
        </>
    )
}