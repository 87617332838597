

import {
  Column,
  FilterFn,
  SortingFn,
  Table,
} from '@tanstack/react-table';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap-select/dist/js/bootstrap-select.min.js';
import $ from 'jquery';
import React, { useEffect, useRef } from 'react';

import {
  compareItems,
  rankItem,
} from '@tanstack/match-sorter-utils';
import { InputGroup } from 'react-bootstrap';
import DebouncedInput from '../components/base/debouncedInput.js';


export const reSplitAlphaNumeric = /([0-9]+)/gm;

// Mixed sorting is slow, but very inclusive of many edge cases.
// It handles numbers, mixed alphanumeric combinations, and even
// null, undefined, and Infinity
function compareAlphanumeric(aStr: string, bStr: string) {
  // Split on number groups, but keep the delimiter
  // Then remove falsey split values
  const a = aStr.split(reSplitAlphaNumeric).filter(Boolean);
  const b = bStr.split(reSplitAlphaNumeric).filter(Boolean);

  // While
  while (a.length && b.length) {
    const aa = a.shift()!;
    const bb = b.shift()!;

    const an = parseInt(aa, 10);
    const bn = parseInt(bb, 10);

    const combo = [an, bn].sort();

    // Both are string
    if (isNaN(combo[0]!)) {
      if (aa > bb) {
        return 1;
      }
      if (bb > aa) {
        return -1;
      }
      continue;
    }

    // One is a string, one is a number
    if (isNaN(combo[1]!)) {
      return isNaN(an) ? -1 : 1;
    }

    // Both are numbers
    if (an > bn) {
      return 1;
    }
    if (bn > an) {
      return -1;
    }
  }

  return a.length - b.length;
}

const alphanumeric: SortingFn<any> = (rowA, rowB, columnId) => {
  return compareAlphanumeric(
    textContent(rowA.getValue(columnId)).toLowerCase(),
    textContent(rowB.getValue(columnId)).toLowerCase(),
  );
};

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(textContent(row.getValue(columnId)), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!,
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? alphanumeric(rowA, rowB, columnId) : dir;
};

/**
 * Traverse any props.children to get their combined text content
 * @param {React.ReactElement | string} elem
 * @return {string}
 */
function textContent(elem: React.ReactElement | string): string {
  if (!elem) {
    return '';
  }
  if (typeof elem === 'string') {
    return elem;
  }
  // Debugging for basic content shows that props.children, if any, is either a
  // ReactElement, or a string, or an Array with any combination. Like for
  // `<p>Hello <em>world</em>!</p>`:
  //
  //   $$typeof: Symbol(react.element)
  //   type: "p"
  //   props:
  //     children:
  //       - "Hello "
  //       - $$typeof: Symbol(react.element)
  //         type: "em"
  //         props:
  //           children: "world"
  //       - "!"
  const children = elem.props && elem.props.children;
  if (children instanceof Array) {
    return children.map(textContent).join(' ');
  }
  return textContent(children);
}

function Filter({
  column,
  table,
}: {
  column: Column<any, unknown>;
  table: Table<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  /* const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : [...new Set(Array.from(column.getFacetedUniqueValues().keys()).map((value) =>
        textContent(value)
      ).sort())],
    [column, firstValue]
  );*/

  return typeof firstValue === 'number' ? (
    <div>
      <InputGroup>
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value: any) =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min ${column.getFacetedMinMaxValues()?.[0] ?
            `(${column.getFacetedMinMaxValues()?.[0]})` :
            ''
            }`}
          className="w-50 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value: any) =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max ${column.getFacetedMinMaxValues()?.[1] ?
            `(${column.getFacetedMinMaxValues()?.[1]})` :
            ''
            }`}
          className="w-50 border shadow rounded"
        />
      </InputGroup>
      <div className="h-1" />
    </div>
  ) : (
    <>
      {/* <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
        </datalist>*/}
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? '') as string}
        onChange={(value: any) => column.setFilterValue(value)}
        className="w-100 border shadow rounded"
        placeholder={`Search... `/* (${sortedUniqueValues.length})`*/}
      />
      <div className="h-1" />
    </>
  );
}

interface ColumnSelectorProps<T> {
  columns: Column<T>[];
}

function ColumnSelector<T>({ columns }: ColumnSelectorProps<T>) {
  const selectRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (selectRef.current) {
      // Initialize the Bootstrap-select plugin on the select element
      ($(selectRef.current) as any).selectpicker(
        {
          countSelectedText: function (numSelected: number, numTotal: number) {
            /* own implementation */
            return (numSelected === 1) ? '{0} column selected' : '{0} columns selected';
          },
        });
      $('.selectpicker').selectpicker('val',
        columns.filter((column) => column.getIsVisible()).map((column) => column.id));
    }
  }, [columns]);

  function handleChange() {
    if (selectRef.current) {
      // Get the selected options
      const selectedOptions = selectRef.current.selectedOptions;
      // Get the selected column ids
      const selectedColumnIds = Array.from(selectedOptions).map(
        (option) => option.value,
      );

      // Update the column visibility
      columns.forEach((column) => {
        if (selectedColumnIds.includes(column.id) && !column.getIsVisible()) {
          column.toggleVisibility();
        } else if (!selectedColumnIds.includes(column.id) && column.getIsVisible()) {
          column.toggleVisibility();
        }
      });
    }
  }

  return (
    <select
      ref={selectRef}
      className="selectpicker"
      multiple
      data-selected-text-format="count > 0"
      data-actions-box="true"
      title="Select columns"
      onChange={handleChange}
    >
      {columns.map((column) => (
        <option key={column.id} value={column.id}>
          {String(column.columnDef.header)}
        </option>
      ))}
    </select>
  );
}

export {
  ColumnSelector, Filter,
  fuzzyFilter,
  fuzzySort
};

