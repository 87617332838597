import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { updateTimeEntry } from "../../../controller/tmetricController";
import { TmetricTimeEntriesResponseType } from "../../../types/tmetric";
import { wait } from "../../../utils/util";
import { useTimeDisplayStore } from "../context/TimeDisplayContext";

interface Props {
  timeEntry: TmetricTimeEntriesResponseType;
  show: boolean;
  onHide: () => void;
}

function EditTimeEntryModal({ timeEntry, show, onHide }: Props) {
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(
    timeEntry?.startTime?.split("T")[1]
  );
  const [endTime, setEndTime] = useState(timeEntry?.endTime?.split("T")[1]);
  const { reload } = useTimeDisplayStore();

  async function handleSave() {
    setLoading(true);
    await updateTimeEntry(timeEntry, startTime, endTime, false);
    await wait();
    setLoading(false);
    onHide();
    reload();
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Time Entry</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <input
              type="time"
              value={startTime}
              onChange={(e: any) => {
                setStartTime(e.target.value);
              }}
            />
          </Col>
          <Col>
            <input
              type="time"
              value={endTime}
              onChange={(e: any) => {
                setEndTime(e.target.value);
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
          {loading && <FontAwesomeIcon spin icon={faClock} className="ms-2" />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditTimeEntryModal;
