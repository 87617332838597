import React, { Component } from "react";
import { Client } from "../../types/client.js";
import { Result } from "../../types/invoicingModel.js";
import MultiSelectDropdownDropdown from "../base/multiselect.js";

interface Props {
  name: string;
  label?: string;
  selectedClient?: Client;
  selectedClients?: Client[];
  onChangeMultiple?: (name: string, clients: Client[]) => void;
  onChangeSingle?: (name: string, client: Client) => void;
  multiple?: boolean;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  reloadTrigger?: number;
  result: Result;
}

interface State {
  clients: Client[];
}

class InvoicingClientDropdown extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      clients: [],
    };
    this.onChange = this.onChange.bind(this);
    this.loadClients = this.loadClients.bind(this);
  }

  componentDidMount(): void {
    this.loadClients();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.reloadTrigger !== this.props.reloadTrigger) {
      this.loadClients();
    }

    if (prevProps.result !== this.props.result) {
      this.loadClients();
    }
  }

  async loadClients() {
    let clients = this.props.result?.clients ?? [];

    clients.map((client) => {
      client.billableRate = client.billableRate
        ? Math.round(client.billableRate * 100) / 100
        : 0;
    });

    this.setState({ clients: clients });
  }

  onChange(name: string, clients: Client[]) {
    if (this.props.onChangeMultiple) {
      this.props.onChangeMultiple(name, clients);
    }
    if (this.props.onChangeSingle) {
      this.props.onChangeSingle(name, clients[0]);
    }
  }

  render() {
    return (
      <MultiSelectDropdownDropdown<Client>
        name={this.props.name}
        textField="name"
        subTextField="billableRate"
        loadItems={this.loadClients.bind(this)}
        options={this.state.clients}
        onChange={this.onChange}
        selectedItems={
          this.props.selectedClients ?? [this.props.selectedClient]
        }
        required={this.props.required}
        multiple={this.props.multiple}
        disabled={this.props.disabled}
      />
    );
  }
}

export default InvoicingClientDropdown;
