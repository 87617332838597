import {
  CalculateTotalHoursResponse,
  Project,
  Result,
  TaskListNamesResponse,
} from "../types/invoicingModel";
import api from "../utils/api";

export const getClientList = async (
  startMonth: string,
  endMonth: string,
  status: string
): Promise<Result> => {
  const startDate = new Date(`${startMonth}-01T00:00:00`);
  const endDate = new Date(`${endMonth}-01T00:00:00`);

  const padMonth = (date: Date) => {
    const month = date.getMonth() + 1; // JavaScript months start from 0
    return month < 10 ? `0${month}` : month;
  };

  const startDateWithDay = `${startDate.getFullYear()}-${padMonth(
    startDate
  )}-01`;

  const lastDayOfEndMonth = new Date(
    endDate.getFullYear(),
    endDate.getMonth() + 1,
    0
  );

  // End day should be last of month
  const endDateWithDay = `${endDate.getFullYear()}-${padMonth(
    endDate
  )}-${lastDayOfEndMonth.getDate()}`;

  try {
    const response = await api.get(
      `/report/hours?startDate=${startDateWithDay}&endDate=${endDateWithDay}${status === "" ? "" : `&status=${status}`
      }`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to get total hours");
  }
};

function msToHours(duration: number) {
  return duration / 3600000; // convert milliseconds to hours
}

export const calculateTotalHours = async (
  clientList: Result
): Promise<CalculateTotalHoursResponse> => {
  const totalHours = msToHours(clientList.duration);
  const billableHours = msToHours(clientList.billableDuration);
  const unbillableHours = msToHours(clientList.unbillableDuration);
  const billableAmount = Array.isArray(clientList.billableAmount)
    ? clientList.billableAmount.reduce((total, ba) => total + ba.amount, 0)
    : clientList.billableAmount;
  const averageBillableRate = clientList.billableRate;
  return {
    totalHours: totalHours,
    billableHours: billableHours,
    unbillableHours: unbillableHours,
    averageBillableRate: averageBillableRate,
    totalAmount: billableAmount,
  };
};

export const getTaskListNames = async (
  project: Project
): Promise<TaskListNamesResponse[]> => {
  if (!project?.tasks) {
    return [];
  }

  let taskIds = [];
  for (let task of project.tasks) {
    if (task.id) {
      taskIds.push(task.id);
    }
  }
  try {
    const response = await api.get(
      `/clickup/tasks/?ids=${JSON.stringify(taskIds)}`,
      { timeout: 20000 }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to get task list");
  }
};

export const markAsInvoiced = async (
  project: Project,
  invoicingStartDate: Date,
  invoicingEndDate: Date
): Promise<void> => {
  let body = {
    projectId: project.id,
    startDate: invoicingStartDate.toISOString().slice(0, -14),
    endDate: invoicingEndDate.toISOString().slice(0, -14),
    invoiced: true,
  };
  try {
    await api.post("/tmetric/markInvoiced", body);
  } catch (error) {
    console.error(error);
    throw new Error("Failed to mark as invoiced");
  }
};

export const getDistance = async (projectId: string): Promise<number> => {
  try {
    const response = await api.get(`/tmetric/projects/${projectId}`);
    return response.data.distance;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to get distance");
  }
};
