// MultiSelectDropdownDropdown.tsx
import "bootstrap-select/dist/css/bootstrap-select.min.css";
import "bootstrap-select/dist/js/bootstrap-select.min.js";
import $ from "jquery";
import _ from "lodash";
import React, { Component } from "react";

interface Selectable {
  id: number | string;
}

interface Props<T extends Selectable> {
  name: string;
  textField: keyof T;
  subTextField?: keyof T;
  loadItems: () => Promise<T[]>;
  onChange?: (name: string, items: T[]) => void;
  selectedItems?: T[];
  multiple?: boolean;
  required?: boolean;
  disabled?: boolean;
  options: T[];
}

interface State<T extends Selectable> {
  selectedItems?: T[];
  error: string | null;
  loading: boolean;
}

class MultiSelectDropdown<T extends Selectable> extends Component<
  Props<T>,
  State<T>
> {
  selectRef: React.RefObject<HTMLSelectElement>;
  observer: IntersectionObserver;

  constructor(props: Props<T>) {
    super(props);
    this.selectRef = React.createRef();
    this.state = {
      selectedItems: props.selectedItems || [],
      loading: true,
      error: null,
    };
    this.handleIntersection = this.handleIntersection.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.observer = new IntersectionObserver(this.handleIntersection);
  }

  componentDidMount() {
    $(this.selectRef.current).selectpicker();
    this.observer.observe(this.selectRef.current);
  }

  componentDidUpdate(prevProps: Props<T>, prevState: State<T>) {
    if (!_.isEqual(prevProps.options, this.props.options)) {
      const selectedValues = this.state.selectedItems.map((item) => {
        return String(item?.id);
      });
      $(this.selectRef.current).selectpicker("destroy");
      $(this.selectRef.current).selectpicker("val", selectedValues);
    }
    if (!_.isEqual(this.props.selectedItems || [], this.state.selectedItems)) {
      const selectedItems = this.props.selectedItems || [];
      const selectedValues = selectedItems.map((item) => {
        return String(item?.id);
      });
      $(this.selectRef.current).selectpicker("destroy");
      $(this.selectRef.current).selectpicker("val", selectedValues);
      this.setState({ selectedItems: selectedItems });
    }
    if (prevProps.disabled !== this.props.disabled) {
      const selectedItems = this.props.selectedItems || [];
      const selectedValues = selectedItems.map((item) => {
        return String(item?.id);
      });
      $(this.selectRef.current).selectpicker("destroy");
      $(this.selectRef.current).selectpicker("val", selectedValues);
    }
  }

  componentWillUnmount() {
    this.observer.unobserve(this.selectRef.current);
  }

  /**
   * Handle intersection changes for the select element
   * @param {Array.<IntersectionObserverEntry>} entries - An array of intersection observer entries
   */
  async handleIntersection(entries: IntersectionObserverEntry[]) {
    entries.forEach(async (entry) => {
      if (entry.isIntersecting) {
        try {
          await this.props.loadItems();
          this.setState({ loading: false });
        } catch (error) {
          console.log(error);
          this.setState({ loading: false, error: error.message });
        }
        this.observer.unobserve(entry.target);
      }
    });
  }

  /**
   * Handle changes to the selected values
   * @param {React.ChangeEvent<HTMLSelectElement>} e - The change event
   */
  handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const selectedIds = [...e.target.selectedOptions].map((o) => o.value);
    const selectedItems = this.props.options.filter((item) =>
      selectedIds.includes(String(item.id))
    );
    this.setState({ selectedItems });
    if (this.props.onChange) {
      this.props.onChange(this.props.name, selectedItems);
    }
  }

  render() {
    const { options, name } = this.props;

    return (
      <div className="form-control p-0" key={name + options.length}>
        <select
          ref={this.selectRef}
          name={this.props.name}
          className="selectpicker sp-w-100"
          multiple={this.props.multiple}
          data-live-search="true"
          onChange={this.handleChange}
          required={this.props.required}
          disabled={this.props.disabled}
        >
          <option data-hidden="true"></option>
          {options.map((option) => (
            <option
              key={option.id}
              value={option.id}
              data-subtext={option[this.props.subTextField]}
            >
              {option[this.props.textField]?.toString() ?? ""}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default MultiSelectDropdown;
