import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import EditInventoryItemModal from '../components/inventory/editInventoryItemModal';
import { InventoryItem } from '../components/inventory/inventoryItemType';
import InventoryItemsTable from '../components/inventory/inventoryTable';
import LoadingSpinner from '../components/loadingSpinner';
import api from '../utils/api';

interface Props {
}

interface State {
    showCreate: boolean;
    submittedId: string;
    loading: boolean;
    items: any[];
}

class InventoryPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showCreate: false,
            submittedId: '',
            items: [],
            loading: true
        };

    }

    componentDidMount(): void {
        this.refreshItems();
    }

    refreshItems = async () => {
        let result = await api.get('/inventoryItems');
        this.setState({ items: result.data, loading: false });
    };

    handleCreate = () => {
        this.setState({ showCreate: true });
    };

    handleCreateCancel = () => {
        this.setState({ showCreate: false });
    };

    handleCreateSubmit = (item: InventoryItem) => {
        // Scale Ticket fast scale in returns null not an item with an id
        if (item) {
            this.setState({ showCreate: false, submittedId: item.id });
        } else {
            this.setState({ showCreate: false });
        }
        this.refreshItems();
    };


    render() {
        if (this.state.loading) {
            return <LoadingSpinner minHeight='100vh' />;
        }
        return (
            <div className='container mt-3'>
                <InventoryItemsTable items={this.state.items} />
                <div className="position-fixed bottom-0 end-0">
                    <Button variant="floating"
                        className="btn btn-primary btn-floating m-3" onClick={this.handleCreate}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </div>
                <EditInventoryItemModal
                    onSubmit={this.handleCreateSubmit} onCancel={this.handleCreateCancel} show={this.state.showCreate} />
            </div>
        );
    }
}

export default InventoryPage;