import React, { useEffect, useState } from "react";
import flameGif from "./flame.gif";
import "./index.css";

const FlameCursor: React.FC = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setPosition({
        x: e.clientX,
        y: e.clientY,
      });
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div
      className="flame-gif"
      style={{
        left: `${position.x - 20}px`,
        top: `${position.y - 60}px`,
        backgroundImage: `url(${flameGif})`,
      }}
    ></div>
  );
};

export default FlameCursor;
