import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, ListGroup, Row } from "react-bootstrap";
import { TmetricTimeEntriesResponseType } from "../../../types/tmetric";
import { useTotalHoursForTimeEntry } from "../hooks/useTotalHoursForTimeEntry";
import { hoursToHm } from "../utils/util";

interface Props {
  timeEntry: TmetricTimeEntriesResponseType;
}

function BreakEntry({ timeEntry }: Props) {
  const totalHours = useTotalHoursForTimeEntry(timeEntry);

  return (
    <ListGroup.Item className="mb-0">
      <Row>
        <Col xs={4}>
          <p className="my-auto">{timeEntry.details.description}</p>
        </Col>
        <Col xs={4}></Col>
        <Col xs={2}>
          <div className="d-flex align-items-center bg-light rounded p-1 ms-1">
            <p className="mb-0">
              {timeEntry.startTime.split("T")[1].slice(0, 5)} -{" "}
              {timeEntry.endTime.split("T")[1].slice(0, 5)}
            </p>
          </div>
        </Col>
        <Col xs={2}>
          <div className="d-flex justify-content-end align-items-center my-auto h-100">
            <p className="mb-0 fw-bold">{hoursToHm(totalHours)}</p>
            <Button variant="success" size="sm" className="ms-3 invisible">
              <FontAwesomeIcon icon={faPlay} />
            </Button>
          </div>
        </Col>
      </Row>
    </ListGroup.Item>
  );
}

export default BreakEntry;
