import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { InputGroup } from "react-bootstrap";

interface Props {
  startMonth: string;
  endMonth: string;
  onStartMonthChange: (month: string) => void;
  onEndMonthChange: (month: string) => void;
}

interface State {
  // TODO
}

export default class MonthSelect extends Component<Props, State> {
  render() {
    return (
      <div className="form-group row">
        <div className="col-6">
          <label className="form-label">Start Month</label>
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <FontAwesomeIcon icon={faCalendarAlt} />
            </InputGroup.Text>
            <input
              type="month"
              className="form-control datepicker"
              value={this.props.startMonth}
              onChange={(e) => this.props.onStartMonthChange(e.target.value)}
            />
          </InputGroup>
        </div>

        <div className="col-6">
          <label className="form-label">End Month</label>
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <FontAwesomeIcon icon={faCalendarAlt} />
            </InputGroup.Text>
            <input
              type="month"
              className="form-control datepicker"
              value={this.props.endMonth}
              onChange={(e) => this.props.onEndMonthChange(e.target.value)}
            />
          </InputGroup>
        </div>
      </div>
    );
  }
}
