import { Client } from "../types/client";
import { Project } from "../types/project";
import api from "../utils/api";

export const getClients = async (): Promise<Client[]> => {
  try {
    const response = await api.get(`/nextcloud/getClients`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getProjects = async (client: Client): Promise<Project[]> => {
  try {
    const response = await api.get(
      `/nextcloud/getProjects/?folder=${client.name}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const uploadFile = async (
  file: File,
  client: Client,
  project: Project
): Promise<void> => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("clientFolder", client.name);
    formData.append("projectFolder", project.name);
    await api.post(`/nextcloud/uploadFile`, formData);
  } catch (error) {
    console.error(error);
  }
};
