import React, { Component } from "react";
import {
  Col,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Client, Result } from "../../types/invoicingModel";
import InvoicingClientDropdown from "./InvoicingClientDropdown";

interface Props {
  result: Result;
  onClientChange: (client: Client) => void;
  loading: boolean;
}

interface State {
  totalHours: number;
  billableHours: number;
  unbillableHours: number;
  averageBillableRate: number;
  totalAmount: number;
  selectedClient: Client;
}

export default class TotalHours extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      totalHours: 0,
      billableHours: 0,
      unbillableHours: 0,
      averageBillableRate: 0,
      totalAmount: 0,
      selectedClient: undefined,
    };

    this.onClientChange = this.onClientChange.bind(this);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (this.state.selectedClient !== prevState.selectedClient) {
      this.calculateInfo();
    }

    if (this.props.result !== prevProps.result) {
      this.setState({ selectedClient: undefined });
      this.calculateInfo();
    }

    if (this.props.loading !== prevProps.loading) {
      this.calculateInfo();
    }
  }

  calculateInfo() {
    const { selectedClient } = this.state;

    if (!selectedClient) {
      this.setState({
        totalHours: 0,
        billableHours: 0,
        unbillableHours: 0,
        averageBillableRate: 0,
        totalAmount: 0,
      });
      return;
    }

    // convert the durations from milliseconds to hours
    const totalHours = selectedClient.duration / 3600000;
    const billableHours = selectedClient.billableDuration / 3600000;
    const unbillableHours = selectedClient.unbillableDuration / 3600000;

    // calculate the total billable amount and average billable rate
    let totalAmount = 0;
    let totalBillableAmount = 0;

    if (Array.isArray(selectedClient.billableAmount)) {
      selectedClient.billableAmount.forEach((bill) => {
        totalAmount += bill.amount;
      });
    } else {
      totalAmount = selectedClient.billableAmount;
    }

    totalBillableAmount = totalAmount;

    const averageBillableRate =
      billableHours > 0 ? totalBillableAmount / billableHours : 0;

    // round totalAmount to two decimal places
    totalAmount =
      typeof totalAmount === "number" ? Number(totalAmount.toFixed(2)) : 0;

    // set the state with the calculated values
    this.setState({
      totalHours,
      billableHours,
      unbillableHours,
      averageBillableRate,
      totalAmount,
    });
  }

  onClientChange(name: string, client: Client) {
    this.setState({ selectedClient: client }, () => {
      this.props.onClientChange(client);
    });
  }

  render() {
    return (
      <Container className="border rounded p-2">
        <Row>
          <h5>Client</h5>
        </Row>
        <Row>
          <Col md={6}>
            <InvoicingClientDropdown
              name="client"
              result={this.props.result}
              onChangeSingle={this.onClientChange}
              selectedClient={this.state.selectedClient}
            />
          </Col>
        </Row>
        <Row className="mt-3 mb-3">
          <FormGroup className="col-4 col-md-2">
            <FormLabel>Total Hours</FormLabel>
            <InputGroup>
              <FormControl value={this.state.totalHours || 0} disabled={true} />
              <InputGroup.Text>hours</InputGroup.Text>
            </InputGroup>
          </FormGroup>
          <FormGroup className="col-4 col-md-2">
            <FormLabel>Billable Hours</FormLabel>
            <InputGroup>
              <FormControl
                value={this.state.billableHours || 0}
                disabled={true}
              />
              <InputGroup.Text>hours</InputGroup.Text>
            </InputGroup>
          </FormGroup>
          <FormGroup className="col-4 col-md-2">
            <FormLabel>Unbillable Hours</FormLabel>
            <InputGroup>
              <FormControl
                value={this.state.unbillableHours || 0}
                disabled={true}
              />
              <InputGroup.Text>hours</InputGroup.Text>
            </InputGroup>
          </FormGroup>
          <FormGroup className="col-4 col-md-2">
            <FormLabel>Average Billable Rate</FormLabel>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <FormControl
                value={
                  typeof this.state.averageBillableRate === "number"
                    ? Number(this.state.averageBillableRate.toFixed(2) || 0)
                    : 0
                }
                disabled={true}
              />
              <InputGroup.Text>hour</InputGroup.Text>
            </InputGroup>
          </FormGroup>
          <FormGroup className="col-4 col-md-2">
            <FormLabel>Total Amount</FormLabel>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <FormControl
                value={
                  typeof this.state.totalAmount === "number"
                    ? Number(this.state.totalAmount.toFixed(2) || 0)
                    : 0
                }
                disabled={true}
              />
            </InputGroup>
          </FormGroup>
        </Row>
      </Container>
    );
  }
}
