import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect } from "react";
import { getTasks } from "../../../controller/taskController.js";
import { Task } from "../../../types/task.js";
import MultiSelectDropdown from "../../base/multiselect.js";

interface Props {
  name: string;
  selectedTask?: Task;
  selectedTasks?: Task[];
  onChangeMultiple?: (name: string, tasks: Task[]) => void;
  onChangeSingle?: (name: string, task: Task) => void;
  multiple?: boolean;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  projectId?: number;
}

const TaskDropdown: React.FC<Props> = (props) => {
  const fetchTasks = useCallback(async () => {
    if (!props.projectId) {
      return [];
    }
    return await getTasks(props.projectId);
  }, [props.projectId]);

  const { data: tasks, refetch } = useQuery<Task[], Error>({
    queryKey: ["tasks", props.projectId],
    queryFn: fetchTasks,
    enabled: !!props.projectId,
  });

  useEffect(() => {
    refetch();
  }, [props.projectId, refetch]);

  const onChange = useCallback(
    (name: string, tasks: Task[]) => {
      if (props.onChangeMultiple) {
        props.onChangeMultiple(name, tasks);
      }
      if (props.onChangeSingle) {
        props.onChangeSingle(name, tasks[0]);
      }
    },
    [props]
  );

  return (
    <MultiSelectDropdown<Task>
      name={props.name}
      textField="name"
      loadItems={fetchTasks}
      options={tasks ?? []}
      onChange={onChange}
      selectedItems={props.selectedTasks ?? [props.selectedTask]}
      required={props.required}
      multiple={props.multiple}
      disabled={props.disabled || (tasks && tasks.length === 0)}
    />
  );
};

export default TaskDropdown;
