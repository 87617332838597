import React from "react";
import { Row } from "react-bootstrap";
import TimeDisplay from "../components/timedisplay/TimeDisplay";
import TimeEntry from "../components/timeentry/TimeEntry";


interface Props { }


export default function HoursPage(props: Props) {
  return (
    <div>
      <Row className="mt-3">
        <div className="col">
          <TimeEntry />
        </div>
      </Row>
      <Row className="mt-3">
        <div className="col">
          <TimeDisplay />
        </div>
      </Row>
    </div>
  );
}
