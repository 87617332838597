import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AddBreak from "./AddBreak";
import DateSelection from "./DateSelection";
import StopButton from "./StopButton";
import TotalTime from "./TotalTime";

function Topbar() {
  return (
    <Container className="border rounded p-3">
      {/* <ReactConfetti /> */}
      <Row>
        <Col xs={2} className="d-flex flex-row gap-2">
          <StopButton />
          <TotalTime />
        </Col>
        <Col xs={7} className="d-flex items-center justify-content-center">
          <AddBreak />
        </Col>
        <Col xs={3}>
          <DateSelection />
        </Col>
      </Row>
    </Container>
  );
}

export default Topbar;
