import React, { Component } from "react";
import { getProjectFolders } from "../../controller/photosController";
import { ProjectFolderType } from "../../types/project";
import MultiSelectDropdown from "../base/multiselect.js";

interface Props {
  clientName: string;
  selectedProjectFolder?: ProjectFolderType;
  onChangeSingle?: (name: string, projectFolder: ProjectFolderType) => void;
  required?: boolean;
  className?: string;
  disabled?: boolean;
}

interface State {
  projectFolders: ProjectFolderType[];
}

export default class ProjectFolderDopdown extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      projectFolders: [],
    };
    this.onChange = this.onChange.bind(this);
  }

  async loadProjectFolders() {
    if (!this.props.clientName) {
      return [];
    }

    const projectFolders = await getProjectFolders(this.props.clientName);
    this.setState({ projectFolders });
    return projectFolders;
  }

  onChange(name: string, projectFolders: ProjectFolderType[]) {
    if (this.props.onChangeSingle) {
      this.props.onChangeSingle(name, projectFolders[0]);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.clientName !== prevProps.clientName) {
      this.setState({ projectFolders: [] });
      this.loadProjectFolders();
    }
  }

  render() {
    return (
      <MultiSelectDropdown<ProjectFolderType>
        name={this.props.clientName}
        textField="name"
        loadItems={this.loadProjectFolders.bind(this)}
        options={this.state.projectFolders}
        onChange={this.onChange}
        selectedItems={[this.props.selectedProjectFolder]}
        required={this.props.required}
        multiple={false}
        disabled={this.props.disabled || this.state.projectFolders.length === 0}
      />
    );
  }
}
