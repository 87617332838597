import React, { Component } from "react";
import { getClients } from "../../controller/nextCloudPhotosController.js";
import { Client } from "../../types/client.js";
import MultiSelectDropdown from "../base/multiselect.js";

interface Props {
  name: string;
  label?: string;
  selectedClient?: Client;
  selectedClients?: Client[];
  onChangeMultiple?: (clients: Client[]) => void;
  onChangeSingle?: (client: Client) => void;
  multiple?: boolean;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  reloadTrigger?: number;
}

interface State {
  clients: Client[];
}

class PhotosClientDropdown extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      clients: [],
    };
    this.onChange = this.onChange.bind(this);
    this.loadClients = this.loadClients.bind(this);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.reloadTrigger !== this.props.reloadTrigger) {
      this.loadClients();
    }
  }

  async loadClients() {
    const clients = await getClients();
    this.setState({ clients });
    return clients;
  }

  onChange(name: string, clients: Client[]) {
    if (this.props.onChangeMultiple) {
      this.props.onChangeMultiple(clients);
    }
    if (this.props.onChangeSingle) {
      this.props.onChangeSingle(clients[0]);
    }
  }

  render() {
    return (
      <MultiSelectDropdown<Client>
        name={this.props.name}
        textField="name"
        loadItems={this.loadClients.bind(this)}
        options={this.state.clients}
        onChange={this.onChange}
        selectedItems={
          this.props.selectedClients ?? [this.props.selectedClient]
        }
        required={this.props.required}
        multiple={this.props.multiple}
        disabled={this.props.disabled}
      />
    );
  }
}

export default PhotosClientDropdown;
