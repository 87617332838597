import moment from 'moment';
import React, { Component } from "react";
import { Col, Container, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import ClientHours from "../components/invoicing/ClientHours";
import CopyButtons from "../components/invoicing/CopyButtons";
import MonthSelect from "../components/invoicing/MonthSelect";
import StatusSelect from "../components/invoicing/StatusSelect";
import TaskList from "../components/invoicing/TaskList";
import TotalHours from "../components/invoicing/TotalHours";
import {
  calculateTotalHours,
  getClientList,
} from "../controller/invoicingController";
import {
  CalculateTotalHoursResponse,
  Client,
  Project,
  Result,
  TaskListNamesResponse,
} from "../types/invoicingModel";

interface Props {
  // TODO
}

interface State {
  startMonth: string;
  endMonth: string;
  status: string;
  clientList: Client[];
  totalHours: CalculateTotalHoursResponse;
  result: Result;
  selectedClient: Client;
  selectedProject: Project;
  loading: boolean;
  taskListNames: TaskListNamesResponse[];
  reloadCopyButtons: boolean;
}

export default class InvoicingPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // Month in form of YYYY-MM
      startMonth: moment().subtract(3, 'months').format('YYYY-MM'),
      endMonth: moment().subtract(1, 'month').format('YYYY-MM'),
      clientList: undefined,
      status: "not-invoiced",
      result: undefined,
      totalHours: {
        totalHours: 0,
        billableHours: 0,
        unbillableHours: 0,
        averageBillableRate: 0,
        totalAmount: 0,
      },
      selectedClient: undefined,
      selectedProject: undefined,
      loading: false,
      taskListNames: undefined,
      reloadCopyButtons: false,
    };
    this.fetchTotalHours = this.fetchTotalHours.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.onStartMonthChange = this.onStartMonthChange.bind(this);
    this.onEndMonthChange = this.onEndMonthChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.onClientChange = this.onClientChange.bind(this);
    this.onProjectChange = this.onProjectChange.bind(this);
    this.setTaskListNames = this.setTaskListNames.bind(this);
  }

  fetchTotalHours() {
    this.setState({ loading: true });
    this.setState({
      totalHours: {
        totalHours: 0,
        billableHours: 0,
        unbillableHours: 0,
        averageBillableRate: 0,
        totalAmount: 0,
      },
    });
    getClientList(this.state.startMonth, this.state.endMonth, this.state.status)
      .then((response) => {
        this.setState({ clientList: response.clients });
        this.setState({ result: response });
        const totalHours = calculateTotalHours(response).then((response) => {
          this.setState({ totalHours: response });
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  setLoading(loading: boolean) {
    this.setState({ loading: loading });
  }
  onStartMonthChange = (month: string) => {
    this.setState({ startMonth: month }, () => {
      this.fetchTotalHours();
    });
  };

  onEndMonthChange = (month: string) => {
    this.setState({ endMonth: month }, () => {
      this.fetchTotalHours();
    });
  };

  reloadCopyButtons = () => {
    this.setState({ reloadCopyButtons: !this.state.reloadCopyButtons });
  };

  onStatusChange = async (status: string) => {
    this.setState({ status: status }, () => {
      this.fetchTotalHours();
    });
  };

  reloadTotalHours = () => {
    this.fetchTotalHours();
  };
  onClientChange = (client: Client) => {
    this.setState({ selectedClient: client });
    this.reloadCopyButtons();
  };

  onProjectChange = (project: Project) => {
    this.setState({ selectedProject: project });
  };

  componentDidMount(): void {
    this.fetchTotalHours();
  }

  setTaskListNames = (taskListNames: TaskListNamesResponse[]) => {
    this.setState({ taskListNames: taskListNames });
  };

  render() {
    return (
      <div className="mt-3">
        <Container className="bg-light rounded pt-2">
          <Row>
            <Col md={6} sm={6}>
              <MonthSelect
                startMonth={this.state.startMonth}
                endMonth={this.state.endMonth}
                onStartMonthChange={this.onStartMonthChange}
                onEndMonthChange={this.onEndMonthChange}
              />
            </Col>
            <Col md={3} sm={6}>
              <StatusSelect
                selectedStatus={this.state.status}
                onStatusChange={this.onStatusChange}
              />
            </Col>
            <Col
              md={3}
              className="d-flex justify-content-end align-items-center"
            >
              {this.state.loading && (
                <Spinner
                  animation="border"
                  role="status"
                  className="mx-3"
                  hidden={!this.state.loading}
                ></Spinner>
              )}
            </Col>
          </Row>
        </Container>
        <Container className="bg-light rounded mt-3 pb-3 pt-3">
          <Tabs
            defaultActiveKey="totalHours"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="totalHours" title="Total Hours">
              <Row>
                <Col md={12} className="mt-3">
                  <TotalHours totalHours={this.state.totalHours} />
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="clientAndProject" title="Client & Project">
              <Row>
                <Col md={12} className="mt-3">
                  <ClientHours
                    result={this.state.result}
                    onClientChange={this.onClientChange}
                    loading={this.state.loading}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mt-3">
                  <CopyButtons
                    onProjectChange={this.onProjectChange}
                    result={this.state.result}
                    clickupTasks={this.state.taskListNames}
                    reloadTrigger={this.state.reloadCopyButtons}
                    loading={this.state.loading}
                    setLoading={this.setLoading}
                    reloadTotalHours={this.reloadTotalHours}
                    selectedClient={this.state.selectedClient}
                  />
                </Col>
              </Row>
              <Row>
                <TaskList
                  selectedProject={this.state.selectedProject}
                  result={this.state.result}
                  setLoading={this.setLoading}
                  loading={this.state.loading}
                  setTaskList={this.setTaskListNames}
                  reloadCopyButtons={this.reloadCopyButtons}
                  selectedClient={this.state.selectedClient}
                />
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </div>
    );
  }
}
