import { faAdd, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { FormLabel, InputGroup } from "react-bootstrap";
import { Client } from "../../../types/client";
import { useTimeEntryStore } from "../context/TimeEntryContext";
import ClientDropdown from "../dropdowns/ClientDropdown";

export default function ClientInput() {
  const { selectedClient, setClient, setShowCreateClientModal } =
    useTimeEntryStore();

  const queryClient = useQueryClient();

  const onSelectClient = (name: string, client: Client) => {
    setClient(client);
  };

  const onReloadClient = () => {
    queryClient.invalidateQueries(["clients"]);
  };

  const onShowNewClientModal = () => {
    setShowCreateClientModal(true);
  };

  return (
    <>
      <FormLabel>Client</FormLabel>
      <InputGroup>
        <ClientDropdown
          className=""
          onChangeSingle={onSelectClient}
          selectedClient={selectedClient}
          name={"client"}
        />
        <InputGroup.Text
          className="btn btn-outline-secondary"
          onClick={onShowNewClientModal}
        >
          <FontAwesomeIcon icon={faAdd}></FontAwesomeIcon>
        </InputGroup.Text>
        <InputGroup.Text
          className="btn btn-outline-secondary"
          onClick={onReloadClient}
        >
          <FontAwesomeIcon icon={faRotateRight}></FontAwesomeIcon>
        </InputGroup.Text>
      </InputGroup>
    </>
  );
}
