import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

export interface Props {
}

function NavigateBack(props: Props) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(-1);
  }, [navigate]);
  return (
    <></>
  );
}


export default NavigateBack;
