import moment from 'moment';
import numeral from "numeral";
import React, { Component } from "react";
import { Accordion, Col, Row, Spinner, Table } from "react-bootstrap";
import { getTaskListNames } from "../../controller/invoicingController";
import { Client } from "../../types/client";
import {
  Project,
  Result,
  Task,
  TaskListNamesResponse,
} from "../../types/invoicingModel";

interface Props {
  selectedProject: Project;
  result: Result;
  setLoading: (loading: boolean) => void;
  setTaskList: (taskList: TaskListNamesResponse[]) => void;
  loading: boolean;
  reloadCopyButtons: () => void;
  selectedClient: Client;
}

interface State {
  taskList: Task[];
  taskListNames: TaskListNamesResponse[];
}

const badgeColorDictionary: { [key: string]: string; } = {
  Office: "bg-secondary",
  Standard: "bg-success",
  "N/C": "bg-danger",
  Site: "bg-secondary",
  "Service Call": "bg-success",
};

export default class TaskList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      taskList: undefined,
      taskListNames: undefined,
    };
    this.updateTaskList = this.updateTaskList.bind(this);
    this.getBadgeColor = this.getBadgeColor.bind(this);
  }

  formatResultToTaskList(result: Result) {
    if (!result) return undefined;
    if (!this.props.selectedProject) return undefined;
    const taskList: Task[] = [];
    result.clients.forEach((client) => {
      client.projects.forEach((project) => {
        if (project.id !== this.props.selectedProject.id) return;
        project.tasks.forEach((task) => {
          taskList.push(task);
        });
      });
    });
    return taskList;
  }

  updateTaskList() {
    this.props.setLoading(true);
    this.setState({ taskList: this.formatResultToTaskList(this.props.result) });
    getTaskListNames(this.props.selectedProject)
      .then((response) => {
        this.setState({ taskListNames: response });
        this.props.setTaskList(response);
      })
      .finally(() => {
        this.props.setLoading(false);
        this.props.reloadCopyButtons();
      });
  }

  componentDidMount() {
    this.setState({ taskList: this.formatResultToTaskList(this.props.result) });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.result !== this.props.result) {
      this.updateTaskList();
    }
    if (prevProps.selectedProject !== this.props.selectedProject) {
      this.updateTaskList();
    }
    if (prevProps.selectedClient !== this.props.selectedClient) {
      this.setState({ taskList: undefined, taskListNames: undefined });
    }
  }

  getBadgeColor(tag: string) {
    return badgeColorDictionary[tag];
  }

  render() {
    return (
      <div>
        <Row>
          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>Task</th>
                <th>Descriptions</th>
                <th>Hours</th>
                <th>Rate</th>
                <th>Total</th>
              </tr>
            </thead>
            {this.props.loading ? (
              <Row className="mt-5 justify-content-center">
                <Col className="justify-content-center">
                  <Spinner
                    animation="border"
                    role="status"
                    className="mx-3"
                    hidden={!this.props.loading}
                  ></Spinner>
                </Col>
              </Row>
            ) : (
              <tbody>
                {this.state.taskList &&
                  this.state.taskList.map((task) => {
                    return (
                      <tr key={Math.random()}>
                        <td>
                          {this.state.taskListNames &&
                            this.state.taskListNames.find(
                              (taskListName) =>
                                taskListName.clickUpId === task.id
                            )?.name}
                        </td>
                        <td>
                          <Row>
                            <Col md={12}>
                              <Accordion
                                className="w-100"
                                defaultActiveKey={"0"}
                              >
                                <Accordion.Item eventKey="0" className="w-100">
                                  <Accordion.Header className="w-100">
                                    Descriptions
                                  </Accordion.Header>
                                  <Accordion.Body className="w-100">
                                    {task.entries.map((entry) => {
                                      return (
                                        <div
                                          className="d-flex"
                                          key={Math.random()}
                                        >
                                          {moment(entry.day).format('YYYY-MM-DD') +
                                            ": " +
                                            numeral(
                                              entry.duration / 3600000
                                            ).format("0.00") +
                                            "h"}
                                          <div className="mx-3">
                                            {entry.tags.map((tag) => {
                                              return (
                                                <div
                                                  key={Math.random()}
                                                  className={`badge mx-1 ${this.getBadgeColor(
                                                    tag
                                                  )}`}
                                                >
                                                  {tag}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </Col>
                          </Row>
                        </td>
                        <td>{(task.duration / 3600000).toFixed(2)}</td>
                        <td>${(task.billableRate || 0).toFixed(2)}</td>
                        <td>
                          $
                          {(
                            (task.duration / 3600000) * task.billableRate || 0
                          ).toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            )}
          </Table>
        </Row>
      </div>
    );
  }
}
