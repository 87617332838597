import React from "react";
import { useTimeDisplayStore } from "../context/TimeDisplayContext";
import { hoursToHm } from "../utils/util";

function TotalTime() {
  const { totalHours } = useTimeDisplayStore();

  return (
    <div className="flex-fill h-100 d-flex justify-content-center align-items-center ">
      <h1 className="m-0 p-0">{hoursToHm(totalHours)}</h1>
    </div>
  );
}

export default TotalTime;
