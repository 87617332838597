import { useMemo } from "react";
import { TmetricTimeEntriesResponseType } from "../../../types/tmetric";
import { useTimeDisplayStore } from "../context/TimeDisplayContext";

export function useTotalHoursForTimeEntry(
  timeEntry: TmetricTimeEntriesResponseType
) {
  const { reloadTrigger } = useTimeDisplayStore();

  const totalHours = useMemo(() => {
    if (timeEntry.isBreak) return 0;
    const { startTime, endTime } = timeEntry;
    const start = new Date(startTime);
    const end = endTime ? new Date(endTime) : new Date();
    const durationMs = end.getTime() - start.getTime();
    const hours = Math.floor(durationMs / 3600000);
    const minutes = Math.floor((durationMs % 3600000) / 60000);
    return hours + minutes / 60;
  }, [timeEntry, reloadTrigger]);
  return totalHours;
}
