import {
  faCamera,
  faCheck,
  faRecycle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QrScanner } from "@yudiel/react-qr-scanner";
import * as EXIF from "exif-js";
import jsQR from "jsqr";
import React from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import InventoryLocationDropdown from '../components/inventory/inventoryLocationDropdown';
import ClientDropdown from "../components/timeentry/dropdowns/ClientDropdown";
import ProjectDropdown from "../components/timeentry/dropdowns/ProjectDropdown";
import { uploadPartsToBeBilled } from "../controller/partsToBeBilled";
import { PartsToBeBilledUploadInterface } from "../types/partsToBeBilled";

interface Props { }

interface State extends PartsToBeBilledUploadInterface {
  qrCode: string;
  loading: boolean;
  codeScanned: boolean;
  cameraConstraints: MediaTrackConstraints;
  cameraDevices: MediaDeviceInfo[];
}

class PartsToBeBilledPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      qrCode: "",
      client: undefined,
      project: undefined,
      location: undefined,
      quantity: "1",
      description: "",
      vendor: "",
      vendorPartNumber: "",
      loading: false,
      codeScanned: false,
      cameraConstraints: {
        facingMode: "environment",
      },
      cameraDevices: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.switchCamera = this.switchCamera.bind(this);
  }

  componentDidMount() {
    this.loadDevices();
  }

  loadDevices() {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        this.setState({ cameraDevices: videoDevices });
      })
      .catch((error) => {
        console.log("Error while loading devices: ", error);
      });
  }

  switchCamera() {
    const { cameraConstraints, cameraDevices } = this.state;
    const currentDeviceIndex = cameraDevices.findIndex(
      (device) => device.deviceId === cameraConstraints.deviceId
    );

    const nextDeviceIndex = (currentDeviceIndex + 1) % cameraDevices.length;
    this.setState({
      cameraConstraints: {
        ...cameraConstraints,
        deviceId: cameraDevices[nextDeviceIndex].deviceId,
      },
    });
  }

  fileInputRef = React.createRef<HTMLInputElement>();

  handleButtonClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const exif = EXIF.readFromBinaryFile(reader.result); // get the EXIF data
        const orientation = exif.Orientation;

        const imgReader = new FileReader();
        imgReader.onload = () => {
          const image = new Image();
          image.onload = () => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");

            if (context) {
              let width = image.width;
              let height = image.height;

              // swap width and height for sideways orientations
              if (orientation > 4) {
                canvas.width = height;
                canvas.height = width;
              } else {
                canvas.width = width;
                canvas.height = height;
              }

              // rotate and/or flip the context as needed
              switch (orientation) {
                case 2:
                  context.transform(-1, 0, 0, 1, width, 0);
                  break;
                case 3:
                  context.transform(-1, 0, 0, -1, width, height);
                  break;
                case 4:
                  context.transform(1, 0, 0, -1, 0, height);
                  break;
                case 5:
                  context.transform(0, 1, 1, 0, 0, 0);
                  break;
                case 6:
                  context.transform(0, 1, -1, 0, height, 0);
                  break;
                case 7:
                  context.transform(0, -1, -1, 0, height, width);
                  break;
                case 8:
                  context.transform(0, -1, 1, 0, 0, width);
                  break;
              }

              context.drawImage(image, 0, 0, width, height);

              // Convert canvas to Data URL
              const standardImage = canvas.toDataURL("image/png");

              const standardImageElement = new Image();
              standardImageElement.onload = () => {
                const standardCanvas = document.createElement("canvas");
                const standardContext = standardCanvas.getContext("2d");

                if (standardContext) {
                  standardCanvas.width = standardImageElement.width;
                  standardCanvas.height = standardImageElement.height;
                  standardContext.drawImage(
                    standardImageElement,
                    0,
                    0,
                    standardImageElement.width,
                    standardImageElement.height
                  );

                  const imageData = standardContext.getImageData(
                    0,
                    0,
                    standardCanvas.width,
                    standardCanvas.height
                  );
                  const code = jsQR(
                    imageData.data,
                    imageData.width,
                    imageData.height
                  );

                  if (code) {
                    this.onQrCodeScanned(code.data);
                  } else {
                    alert("No QR code found in image.");
                  }
                }
              };
              standardImageElement.src = standardImage;
            }
          };
          image.src = imgReader.result as string;
        };
        imgReader.readAsDataURL(file);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({ ...prevState, [name]: value }));
  };

  onQrCodeScanned = (qrCode: string) => {
    this.setState({ codeScanned: true });
    let qrCodeCut = qrCode;
    if (qrCode.startsWith("http")) {
      qrCodeCut = qrCode.slice(30);
    }

    const urlParams = new URLSearchParams(qrCodeCut);
    const part = urlParams.get("Part");
    const vendor = urlParams.get("Vendor");
    const location = urlParams.get("Location") != null ? parseInt(urlParams.get("Location")!) : 0;

    console.log(part, vendor);

    this.setState({
      qrCode: qrCodeCut,
      vendorPartNumber: part || this.state.vendorPartNumber,
      vendor: vendor || this.state.vendor,
      location: location || this.state.location,
    });
  };

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({ loading: true });
    uploadPartsToBeBilled({
      client: this.state.client,
      project: this.state.project,
      quantity: this.state.quantity,
      description: this.state.description,
      vendor: this.state.vendor,
      vendorPartNumber: this.state.vendorPartNumber,
      location: this.state.location,
    })
      .then(() => {
        this.setState({
          quantity: "1",
          description: "",
          vendor: "",
          vendorPartNumber: "",
          loading: false,
        });
        alert("Success");
      })
      .catch((error) => {
        alert(error);
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <Container className="mt-3">
        {this.state.loading && (
          <div className="full-page-loader">
            <Spinner
              animation="border"
              role="status"
              className="spinner-center"
            ></Spinner>
          </div>
        )}
        {this.state.codeScanned ? (
          <Container className="border rounded p-2">
            <Container className="w-100 d-flex justify-content-center">
              <h1>
                Code Scanned{" "}
                <FontAwesomeIcon icon={faCheck} color="green"></FontAwesomeIcon>
              </h1>
            </Container>
            <Container className="w-100 d-flex justify-content-center">
              <Button
                className="mt-3 mb-3"
                variant="primary"
                type="button"
                onClick={() => this.setState({ codeScanned: false })}
              >
                Scan Again <FontAwesomeIcon icon={faRecycle}></FontAwesomeIcon>
              </Button>
            </Container>
          </Container>
        ) : (
          <Container className="col-12 col-md-6 d-flex flex-column">
            <QrScanner
              constraints={this.state.cameraConstraints}
              onDecode={this.onQrCodeScanned}
              onError={(error) => console.log(error?.message)}
            />
            <Button onClick={this.switchCamera}>Switch Camera</Button>
            <Container className="d-flex flex-column text-center p-1 mt-1 border rounded">
              <p>Barcode Not Scanning?</p>
              <Button onClick={this.handleButtonClick}>
                Take Picture{" "}
                <FontAwesomeIcon
                  icon={faCamera}
                  className="ms-1"
                ></FontAwesomeIcon>
              </Button>
              <input
                type="file"
                accept="image/*"
                capture="environment"
                onChange={this.handleFileChange}
                ref={this.fileInputRef}
                style={{ display: "none" }}
              />
            </Container>
          </Container>
        )}
        <Form onSubmit={this.handleSubmit}>
          <Form.Group className="p-1 rounded mt-2" controlId="formClient">
            <Form.Label>Client</Form.Label>
            <ClientDropdown
              name="client"
              selectedClient={this.state.client}
              onChangeSingle={(name, client) => this.setState({ client })}
            />
          </Form.Group>

          <Form.Group className="p-1 rounded mt-2" controlId="formProject">
            <Form.Label>Project</Form.Label>
            <ProjectDropdown
              name="project"
              disabled={!this.state.client}
              clientId={this.state.client?.id}
              selectedProject={this.state.project}
              onChangeSingle={(name, project) => this.setState({ project })}
            />
          </Form.Group>

          <Form.Group className="p-1 rounded mt-2" controlId="formQuantity">
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              type="number"
              name="quantity"
              value={this.state.quantity}
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group className="p-1 rounded mt-2" controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              name="description"
              value={this.state.description}
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group className="p-1 rounded mt-2" controlId="formVendor">
            <Form.Label>Vendor</Form.Label>
            <Form.Control
              type="text"
              name="vendor"
              value={this.state.vendor}
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group
            className="p-1 rounded mt-2"
            controlId="formVendorPartNumber"
          >
            <Form.Label>Vendor Part Number</Form.Label>
            <Form.Control
              type="text"
              name="vendorPartNumber"
              value={this.state.vendorPartNumber}
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group className="p-1 rounded mt-2" controlId="formClient">
            <Form.Label>Location</Form.Label>
            <InventoryLocationDropdown
              name="location"
              selectedLocation={{ id: this.state.location }}
              onChangeSingle={(name, location) => this.setState({ location: location.id })}
            />
          </Form.Group>



          <Button className="mt-3 mb-3 w-100" variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Container>
    );
  }
}

export default PartsToBeBilledPage;
