import React, { Component } from "react";
import {
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Row,
} from "react-bootstrap";
import { CalculateTotalHoursResponse } from "../../types/invoicingModel";

interface Props {
  totalHours: CalculateTotalHoursResponse;
}

interface State {}

export default class TotalHours extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Row>
          <FormGroup className="col-4 col-md-2">
            <FormLabel>Total Hours</FormLabel>
            <InputGroup>
              <FormControl
                value={this.props.totalHours.totalHours || 0}
                disabled={true}
              />
              <InputGroup.Text>hours</InputGroup.Text>
            </InputGroup>
          </FormGroup>
          <FormGroup className="col-4 col-md-2">
            <FormLabel>Billable Hours</FormLabel>
            <InputGroup>
              <FormControl
                value={this.props.totalHours.billableHours || 0}
                disabled={true}
              />
              <InputGroup.Text>hours</InputGroup.Text>
            </InputGroup>
          </FormGroup>
          <FormGroup className="col-4 col-md-2">
            <FormLabel>Unbillable Hours</FormLabel>
            <InputGroup>
              <FormControl
                value={this.props.totalHours.unbillableHours || 0}
                disabled={true}
              />
              <InputGroup.Text>hours</InputGroup.Text>
            </InputGroup>
          </FormGroup>
          <FormGroup className="col-4 col-md-2">
            <FormLabel>Average Billable Rate</FormLabel>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <FormControl
                value={
                  typeof this.props.totalHours.averageBillableRate === "number"
                    ? Number(
                        this.props.totalHours.averageBillableRate.toFixed(2) ||
                          0
                      )
                    : 0
                }
                disabled={true}
              />
              <InputGroup.Text>hour</InputGroup.Text>
            </InputGroup>
          </FormGroup>
          <FormGroup className="col-4 col-md-2">
            <FormLabel>Total Amount</FormLabel>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <FormControl
                value={
                  typeof this.props.totalHours.totalAmount === "number"
                    ? Number(this.props.totalHours.totalAmount.toFixed(2) || 0)
                    : 0
                }
                disabled={true}
              />
            </InputGroup>
          </FormGroup>
        </Row>
      </div>
    );
  }
}
