import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useState } from "react";
import { Container, ToastContainer } from "react-bootstrap";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import FlameCursor from "./FlameCursor";
import NavbarComponent from "./components/NavbarComponent";
import PrivateRoute from "./components/PrivateRoute";
import InventoryItemDetails from "./components/inventory/inventoryItemDetails";
import { getTmetricId, getUserprofileId } from "./controller/userController";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import HoursPage from "./pages/HoursPage";
import InventoryPage from "./pages/InventoryPage";
import InvoicingPage from "./pages/InvoicingPage";
import LoginPage from "./pages/LoginPage";
import NextCloudPhotosPage from "./pages/NextCloudPhotosPage";
import PartsToBeBilledPage from "./pages/PartsToBeBilledPage";
import PhotosPage from "./pages/PhotosPage";

const queryClient = new QueryClient();

function App(props: any) {
  const navigate = useNavigate();

  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("access_token") ? true : false
  );
  const [beastMode, setBeastMode] = useState(
    localStorage.getItem("beast_mode") ? true : false
  );

  const toggleBeastMode = () => {
    if (beastMode) {
      localStorage.removeItem("beast_mode");
      setBeastMode(false);
    } else {
      localStorage.setItem("beast_mode", "true");
      setBeastMode(true);
    }
  };

  const handleLogin = (token: string, username: string) => {
    localStorage.setItem("access_token", token);
    setAuthenticated(true);
    navigate("/");
    getTmetricId(username)
      .then((res) => {
        localStorage.setItem("tmetric_id", res);
      })
      .catch((err) => console.log(err));

    getUserprofileId(username).then((res) => {
      localStorage.setItem("userprofileId", res);
    });
  };
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("tmetric_id");
    localStorage.removeItem("userprofileId");
    setAuthenticated(false);
    navigate("/login");
  };

  const handleForgotPassword = () => {
    navigate("/forgotPassword");
  };

  const passwordResetComplete = async () => {
    navigate("/login");
  };

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <NavbarComponent
          authenticated={authenticated}
          handleLogout={handleLogout}
          toggleBeastMode={toggleBeastMode}
        />
        <Container>
          <Routes>
            <Route path="/" element={<Navigate to="/hoursPage" />} />
            <Route
              path="/login"
              element={
                <LoginPage
                  handleLogin={handleLogin}
                  handleForgotPassword={handleForgotPassword}
                />
              }
            />
            <Route
              path="/forgotPassword"
              element={
                <ForgotPasswordPage
                  passwordResetComplete={passwordResetComplete}
                />
              }
            />

            <Route path="*" element={<Navigate to="/hoursPage" />} />

            <Route
              path="/hoursPage"
              element={
                <PrivateRoute authenticated={authenticated}>
                  <HoursPage />
                </PrivateRoute>
              }
            />

            <Route
              path="/partsToBeBilled"
              element={
                <PrivateRoute authenticated={authenticated}>
                  <PartsToBeBilledPage />
                </PrivateRoute>
              }
            />

            <Route
              path="/nextCloudPhotosPage"
              element={
                <PrivateRoute authenticated={authenticated}>
                  <NextCloudPhotosPage />
                </PrivateRoute>
              }
            />

            <Route
              path="/invoicing"
              element={
                <PrivateRoute authenticated={authenticated}>
                  <InvoicingPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/inventoryItems"
              element={
                <PrivateRoute authenticated={authenticated}>
                  <InventoryPage />
                </PrivateRoute>
              }
            />

            <Route
              {...props}
              path="/inventoryItems/:id"
              element={
                <PrivateRoute authenticated={authenticated}>
                  <InventoryItemDetails />
                </PrivateRoute>
              }
            />

            <Route
              path="/photosPage"
              element={
                <PrivateRoute authenticated={authenticated}>
                  <PhotosPage />
                </PrivateRoute>
              }
            />
          </Routes>
        </Container>
        <ToastContainer />
        {beastMode && <FlameCursor />}
      </QueryClientProvider>
    </div>
  );
}

export default App;
