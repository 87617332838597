import { faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { stopClock } from "../../../controller/hoursController";
import { wait } from "../../../utils/util";
import { useTimeDisplayStore } from "../context/TimeDisplayContext";

function StopButton() {
  const [isToday, setIsToday] = React.useState(false);

  const {
    loading,
    timeEntriesLoading,
    timerLoading,
    timer,
    date,
    setLoading,
    reload,
  } = useTimeDisplayStore();

  const handleStop = async () => {
    setLoading(true);
    await stopClock();
    wait(); // Wait becuz tmetric api is slow
    setLoading(false);
    reload();
  };

  React.useEffect(() => {
    if (date === moment().format("YYYY-MM-DDT00:00:00")) {
      setIsToday(true);
    } else {
      setIsToday(false);
    }
  }, [date]);

  return (
    <div className="flex-fill h-100 d-flex justify-content-center align-items-center">
      {loading || timeEntriesLoading || timerLoading ? (
        <Spinner animation="border" />
      ) : (
        <Button
          variant={!isToday || !timer?.isStarted ? "secondary" : "danger"}
          disabled={!isToday || !timer?.isStarted}
          className="rounded-circle"
          onClick={handleStop}
        >
          <FontAwesomeIcon icon={faStop} />
        </Button>
      )}
    </div>
  );
}

export default StopButton;
