import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import numeral from "numeral";
import React from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  getDistance,
  markAsInvoiced,
} from "../../controller/invoicingController";
import {
  Client,
  Project,
  Result,
  TaskListNamesResponse,
} from "../../types/invoicingModel";
import InvoicingProjectDropdown from "./InvoicingProjectDropdown";
import ProjectHours from "./ProjectHours";

const TRAVEL_RATE = 0.8;
const TRAVEL_LOCAL_RATE = 0.4;
const TRAVEL_LOCAL_KM = 25;
const TRAVEL_ITEM = "Travel";
const TRAVEL_LOCAL_ITEM = "Travel:Local";
const BUTTON_COPIED_VARIANT = "outline-success";
const BUTTON_COPIED_TEXT = "Copied";

interface Props {
  result: Result;
  onProjectChange: (project: Project) => void;
  setLoading: (loading: boolean) => void;
  clickupTasks: TaskListNamesResponse[];
  reloadTrigger: boolean;
  loading: boolean;
  selectedClient: Client;
  reloadTotalHours: () => void;
}

interface State {
  labourItem: string;
  labourDescription: string;
  travelItem: string;
  travelDescription: string;
  selectedProject: Project;
  verifyTotal: string;
  verifyTravelTotal: string;
  projectDistance: number;
  totalSiteVisits: number;
  totalDaysWithSiteVisits: number;
  travelRate: number;
  invoicingStartDate: Date;
  invoicingEndDate: Date;
  labourItemCopied: boolean;
  labourDescriptionCopied: boolean;
  travelItemCopied: boolean;
  travelDescriptionCopied: boolean;
  labourQuantityCopied: boolean;
  travelQuantityCopied: boolean;
  travelQuantity: number;
}

export default class CopyButtons extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      labourItem: "Lab:Ind/Ag",
      travelItem: "Travel:Local",
      labourDescription: "",
      travelDescription: "",
      selectedProject: undefined,
      verifyTotal: "",
      verifyTravelTotal: "",
      projectDistance: 0,
      totalSiteVisits: 0,
      totalDaysWithSiteVisits: 0,
      travelRate: 0,
      invoicingStartDate: undefined,
      invoicingEndDate: undefined,
      labourItemCopied: false,
      labourDescriptionCopied: false,
      travelItemCopied: false,
      travelDescriptionCopied: false,
      labourQuantityCopied: false,
      travelQuantityCopied: false,
      travelQuantity: 0,
    };

    this.onProjectChange = this.onProjectChange.bind(this);
    this.handleCopyLabourItem = this.handleCopyLabourItem.bind(this);
    this.handleCopyLabourDescription =
      this.handleCopyLabourDescription.bind(this);
    this.handleCopyTravelItem = this.handleCopyTravelItem.bind(this);
    this.handleCopyTravelDescription =
      this.handleCopyTravelDescription.bind(this);
    this.markComplete = this.markComplete.bind(this);
  }

  async setItemAndDescription() {
    this.setState({
      labourDescription: "",
      travelDescription: "",
    });

    if (
      !this.props.selectedClient ||
      !this.state.selectedProject ||
      !this.props.clickupTasks
    ) {
      return;
    }

    let siteVisitsPerDay = new Map<string, number>();
    let copyLabourDescription = "";
    let invoicingStartDate: Date;
    let invoicingEndDate: Date;
    if (this.state.selectedProject) {
      for (let task of this.state.selectedProject.tasks) {
        let clickupTask = this.props.clickupTasks.find(
          (t) => t.clickUpId == task.id
        );
        task.name = clickupTask?.name ?? "No Name";

        copyLabourDescription +=
          task.name +
          ": " +
          numeral(task.duration / 3600000).format("0.00") +
          "h\n";
        let entries = "";
        for (let entry of task.entries) {
          let date = moment(entry.day);
          // Check if date is first or last of invoicing date
          if (!invoicingStartDate || date.isBefore(invoicingStartDate, "day")) {
            invoicingStartDate = date.toDate();
          }
          if (!invoicingEndDate || date.isAfter(invoicingEndDate, "day")) {
            invoicingEndDate = date.toDate();
          }
          let entryDate = date.format("YYYY-MM-DD");
          let entryDateAndHours =
            entryDate +
            ": " +
            numeral(entry.duration / 3600000).format("0.00") +
            "h";
          let entryDescrition = "";
          if (entry.description && entry.description != task.name) {
            entryDescrition = " - " + entry.description;
          }
          copyLabourDescription +=
            "    " + entryDateAndHours + entryDescrition + "\n";
          entries += entryDateAndHours + entryDescrition;

          if (entry.tags.includes("Site")) {
            siteVisitsPerDay.set(
              entryDate,
              (siteVisitsPerDay.get(entryDate) ?? 0) + 1
            );
          }
        }
      }

      let total = 0;
      let days = 0;
      for (let [key, value] of siteVisitsPerDay) {
        total += value;
        days++;
      }

      if (this.state.selectedProject.distance > TRAVEL_LOCAL_KM) {
        this.setState({ travelRate: TRAVEL_RATE });
        this.setState({ travelItem: TRAVEL_ITEM });
      } else {
        this.setState({ travelRate: TRAVEL_LOCAL_RATE });
        this.setState({ travelItem: TRAVEL_LOCAL_ITEM });
      }

      this.setState(
        {
          labourDescription: copyLabourDescription,
          travelDescription:
            "Travel to site - " +
            total +
            " trip" +
            (total > 1 || total == 0 ? "s" : ""),
          projectDistance: this.state.selectedProject.distance,
          totalSiteVisits: total,
          totalDaysWithSiteVisits: days,
          invoicingStartDate: invoicingStartDate,
          invoicingEndDate: invoicingEndDate,
        },
        () => {}
      );
    }
  }

  async getTravelQuantity() {
    getDistance(this.state.selectedProject.id)
      .then((distance) => {
        let siteVisitsPerDay = 0;
        for (let task of this.state.selectedProject.tasks) {
          for (let entry of task.entries) {
            for (let tag of entry.tags) {
              if (tag == "Site") {
                siteVisitsPerDay++;
              }
            }
          }
        }

        this.setState({
          travelQuantity: distance * 2 * siteVisitsPerDay,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.setItemAndDescription();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.selectedProject !== this.state.selectedProject) {
      this.setItemAndDescription();
      this.getTravelQuantity();
    }

    if (prevProps.reloadTrigger !== this.props.reloadTrigger) {
      this.setItemAndDescription();
    }

    if (prevProps.loading !== this.props.loading) {
      this.setState({
        labourItemCopied: false,
        labourDescriptionCopied: false,
        travelItemCopied: false,
        travelDescriptionCopied: false,
      });
      if (this.props.loading) {
        this.setState({
          labourDescription: "",
          travelDescription: "",
        });
      }
    }
  }

  handleCopyLabourItem = () => {
    navigator.clipboard.writeText(this.state.labourItem);
    this.setState({
      labourItemCopied: true,
    });
  };

  handleCopyLabourDescription = () => {
    navigator.clipboard.writeText(this.state.labourDescription);
    this.setState({
      labourDescriptionCopied: true,
    });
  };

  handleCopyTravelItem = () => {
    navigator.clipboard.writeText(this.state.travelItem);
    this.setState({
      travelItemCopied: true,
    });
  };

  handleCopyTravelDescription = () => {
    navigator.clipboard.writeText(this.state.travelDescription);
    this.setState({
      travelDescriptionCopied: true,
    });
  };

  handleCopyLabourQuantity = () => {
    navigator.clipboard.writeText(
      numeral(this.state.selectedProject.billableDuration / 3600000).format(
        "0.00"
      )
    );
    this.setState({
      labourQuantityCopied: true,
    });
  };

  handleCopyTravelQuantity = () => {
    navigator.clipboard.writeText(this.state.travelQuantity.toString());
    this.setState({
      travelQuantityCopied: true,
    });
  };

  onProjectChange(name: string, project: Project) {
    this.setState({ selectedProject: project }, () => {
      this.props.onProjectChange(project);
    });
  }

  async markComplete() {
    const confirm = window.confirm(
      "Are you sure you want to mark this project as invoiced?"
    );
    if (this.state.selectedProject && confirm) {
      markAsInvoiced(
        this.state.selectedProject,
        this.state.invoicingStartDate,
        this.state.invoicingEndDate
      )
        .then(() => {
          this.props.onProjectChange(undefined);
          this.setState({ selectedProject: undefined });
          this.props.reloadTotalHours();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  render() {
    return (
      <div>
        <Row className="mb-3">
          <h5 className="mx-1">Project</h5>
          <Col md={6}>
            <InvoicingProjectDropdown
              name="Project"
              result={this.props.result}
              onChangeSingle={this.onProjectChange}
              selectedProject={this.state.selectedProject}
              selectedClient={this.props.selectedClient}
              reloadTrigger={this.props.reloadTrigger}
            />
          </Col>
          <Col md={6}>
            <Row className="ms-2 me-2">
              <button
                className="btn btn-success"
                disabled={this.props.loading}
                onClick={this.markComplete}
              >
                <FontAwesomeIcon icon={faCheck} /> Mark Complete
              </button>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Container fluid className="border rounded p-2">
              <Row>
                <h5>Labour</h5>
              </Row>
              <Row>
                <Col>
                  <InputGroup className="d-flex flex-nowrap">
                    <InputGroup.Text className="overflow-hidden text-truncate flex-grow-1">
                      {this.state.labourItem}
                    </InputGroup.Text>
                    <Button
                      className=""
                      variant={
                        this.state.labourItemCopied
                          ? BUTTON_COPIED_VARIANT
                          : "outline-primary"
                      }
                      onClick={this.handleCopyLabourItem}
                      disabled={this.props.loading}
                    >
                      {this.state.labourItemCopied
                        ? BUTTON_COPIED_TEXT
                        : " Copy "}
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputGroup className="d-flex flex-nowrap">
                    <InputGroup.Text className="overflow-hidden text-truncate flex-grow-1">
                      {this.state.labourDescription}
                    </InputGroup.Text>
                    <Button
                      variant={
                        this.state.labourDescriptionCopied
                          ? BUTTON_COPIED_VARIANT
                          : "outline-primary"
                      }
                      onClick={this.handleCopyLabourDescription}
                      disabled={
                        this.props.loading || !this.state.selectedProject
                      }
                    >
                      {this.state.labourDescriptionCopied
                        ? BUTTON_COPIED_TEXT
                        : " Copy "}
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputGroup className="d-flex flex-nowrap">
                    <InputGroup.Text className="overflow-hidden text-truncate flex-grow-1">
                      {numeral(
                        this.state.selectedProject?.billableDuration / 3600000
                      ).format("0.00")}
                    </InputGroup.Text>
                    <Button
                      variant={
                        this.state.labourQuantityCopied
                          ? BUTTON_COPIED_VARIANT
                          : "outline-primary"
                      }
                      onClick={this.handleCopyLabourQuantity}
                      disabled={
                        this.props.loading || !this.state.selectedProject
                      }
                    >
                      {this.state.labourQuantityCopied
                        ? BUTTON_COPIED_TEXT
                        : " Copy "}
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md={3}>
            <Container fluid className="border rounded p-2 h-full">
              <Row>
                <h5>Travel</h5>
              </Row>
              <Row>
                <InputGroup className="d-flex flex-nowrap">
                  <InputGroup.Text className="overflow-hidden text-truncate flex-grow-1">
                    {this.state.travelItem}
                  </InputGroup.Text>
                  <Button
                    className=""
                    variant={
                      this.state.travelItemCopied
                        ? BUTTON_COPIED_VARIANT
                        : "outline-primary"
                    }
                    onClick={this.handleCopyTravelItem}
                    disabled={this.props.loading}
                  >
                    {this.state.travelItemCopied
                      ? BUTTON_COPIED_TEXT
                      : " Copy "}
                  </Button>
                </InputGroup>
              </Row>
              <Row>
                <InputGroup className="d-flex flex-nowrap">
                  <InputGroup.Text className="overflow-hidden text-truncate flex-grow-1">
                    {this.state.travelDescription}
                  </InputGroup.Text>
                  <Button
                    variant={
                      this.state.travelDescriptionCopied
                        ? BUTTON_COPIED_VARIANT
                        : "outline-primary"
                    }
                    onClick={this.handleCopyTravelDescription}
                    disabled={this.props.loading || !this.state.selectedProject}
                  >
                    {this.state.travelDescriptionCopied
                      ? BUTTON_COPIED_TEXT
                      : " Copy "}
                  </Button>
                </InputGroup>
              </Row>
              <Row>
                <InputGroup className="d-flex flex-nowrap">
                  <InputGroup.Text className="overflow-hidden text-truncate flex-grow-1">
                    {this.state.travelQuantity}
                  </InputGroup.Text>
                  <Button
                    variant={
                      this.state.travelQuantityCopied
                        ? BUTTON_COPIED_VARIANT
                        : "outline-primary"
                    }
                    onClick={this.handleCopyTravelQuantity}
                    disabled={this.props.loading || !this.state.selectedProject}
                  >
                    {this.state.travelQuantityCopied
                      ? BUTTON_COPIED_TEXT
                      : " Copy "}
                  </Button>
                </InputGroup>
              </Row>
            </Container>
          </Col>

          <Col md={6}>
            <Container fluid className="border rounded p-2 h-100">
              <Row>
                <h5>Overview</h5>
              </Row>
              <Row>
                <Row>
                  <Col>
                    <InputGroup>
                      <InputGroup.Text id="inputGroupPrepend">
                        Days With Site Visits
                      </InputGroup.Text>
                      <FormControl
                        type="text"
                        disabled
                        placeholder="Total"
                        aria-describedby="inputGroupPrepend"
                        value={this.state.totalDaysWithSiteVisits}
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <InputGroup>
                      <InputGroup.Text id="inputGroupPrepend">
                        Total Site Visits
                      </InputGroup.Text>
                      <FormControl
                        type="text"
                        disabled
                        placeholder="Total"
                        aria-describedby="inputGroupPrepend"
                        value={this.state.totalSiteVisits}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container fluid className="border rounded p-2 mt-3">
              <ProjectHours
                result={this.props.result}
                selectedProject={this.state.selectedProject}
              />
            </Container>
          </Col>
        </Row>
      </div>
    );
  }
}
