import {
  faAngry,
  faBoxOpen,
  faCamera,
  faClock,
  faFileInvoiceDollar,
  faQrcode,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface Props {
  authenticated: boolean;
  handleLogout: () => void;
  toggleBeastMode: () => void;
}

const NavbarComponent: React.FC<Props> = ({
  authenticated,
  handleLogout,
  toggleBeastMode,
}) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleNavbarClick = (path: string) => {
    // Collapse the navbar
    navigate(path);
    setExpanded(false);
  };

  const handleLogoutClick = () => {
    // Collapse the navbar
    handleLogout();
    setExpanded(false);
  };
  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="md"
      expanded={expanded}
      className="navbar"
    >
      <div className="container-fluid">
        <Navbar.Brand>
          <span>
            <img
              src="/logo512.png"
              width="30"
              height="30"
              alt="Atom Hours Logo"
              className="d-inline-block align-top me-2"
            />
            ATOM HOURS
          </span>
        </Navbar.Brand>
        {authenticated && (
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => {
              setExpanded(!expanded);
            }}
          />
        )}
        {authenticated && (
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                onClick={() => handleNavbarClick("/hoursPage")}
                className="nav-link"
              >
                <FontAwesomeIcon icon={faClock} /> Hours
              </Nav.Link>
              <Nav.Link
                onClick={() => handleNavbarClick("/invoicing")}
                className="nav-link"
              >
                <FontAwesomeIcon icon={faFileInvoiceDollar} /> Invoicing
              </Nav.Link>
              <Nav.Link
                onClick={() => handleNavbarClick("/photosPage")}
                className="nav-link"
              >
                <FontAwesomeIcon icon={faCamera} /> Photos
              </Nav.Link>
              <Nav.Link
                onClick={() => handleNavbarClick("/nextCloudPhotosPage")}
                className="nav-link"
              >
                <FontAwesomeIcon icon={faCamera} /> Next Cloud Photos
              </Nav.Link>
              <Nav.Link
                onClick={() => handleNavbarClick("/partsToBeBilled")}
                className="nav-link"
              >
                <FontAwesomeIcon icon={faQrcode} /> Parts To Be Billed
              </Nav.Link>
              <Nav.Link
                onClick={() => handleNavbarClick("/inventoryItems")}
                className="nav-link"
              >
                <FontAwesomeIcon icon={faBoxOpen} /> Inventory
              </Nav.Link>
            </Nav>
            <Nav className="ms-auto">
              <NavDropdown title="Options" id="nav-dropdown" align="end">
                <NavDropdown.Item
                  onClick={handleLogoutClick}
                  className="dropdown-item"
                >
                  <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={toggleBeastMode}
                  className="dropdown-item"
                >
                  <FontAwesomeIcon icon={faAngry} /> Toggle Beast Mode
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        )}
      </div>
    </Navbar>
  );
};

export default NavbarComponent;
