import {
  faArrowLeft,
  faArrowRight,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTimeDisplayStore } from "../context/TimeDisplayContext";
import "./clock.css";

export default function DateSelection() {
  const { date, setDate } = useTimeDisplayStore();

  function dateBackward() {
    const newDate = moment(date).subtract(1, "days").toDate();
    setDate(moment(newDate).format("YYYY-MM-DDT00:00:00").toString());
  }
  function dateToday() {
    const newDate = moment().toDate();
    setDate(moment(newDate).format("YYYY-MM-DDT00:00:00").toString());
  }
  function dateCustom(date: Date) {
    setDate(moment(date).format("YYYY-MM-DDT00:00:00").toString());
  }
  function dateForward() {
    const newDate = moment(date).add(1, "days").toDate();
    setDate(moment(newDate).format("YYYY-MM-DDT00:00:00").toString());
  }

  return (
    <Container className="">
      <Row className="text-center">
        <Col className="p-0">
          <FontAwesomeIcon
            size={"2x"}
            icon={faArrowLeft}
            className="date-arrow"
            onClick={dateBackward}
          />
        </Col>
        <Col className="p-0">
          <Row>
            <FontAwesomeIcon
              size={"2x"}
              icon={faCalendarAlt}
              className="p-0 mb-3 date-calendar date-calendar"
              onClick={dateToday}
            />
          </Row>
          <Row>
            <input
              type="date"
              className="date-select btn btn-outline-secondary"
              value={date.split("T")[0]}
              onChange={(e) => {
                const date = new Date(e.target.value);
                date.setUTCHours(0, 0, 0, 0);
                dateCustom(date);
              }}
            />
          </Row>
        </Col>
        <Col className="p-0">
          <FontAwesomeIcon
            size={"2x"}
            icon={faArrowRight}
            className="date-arrow"
            onClick={dateForward}
          />
        </Col>
      </Row>
    </Container>
  );
}
