import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import AddBreakModal from "../modals/AddBreakModal";

function AddBreak() {
  const [showAddBreakModal, setShowAddBreakModal] = useState(false);

  return (
    <div className="d-flex h-100 items-center">
      <Button
        variant="secondary"
        className="my-auto"
        onClick={() => setShowAddBreakModal(true)}
      >
        Add Break
        <FontAwesomeIcon icon={faCoffee} className="ms-2" />
      </Button>
      <AddBreakModal
        show={showAddBreakModal}
        onHide={() => setShowAddBreakModal(false)}
      />
    </div>
  );
}

export default AddBreak;
