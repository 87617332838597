import React, { Component } from "react";
import { Col, FormControl, FormLabel, InputGroup, Row } from "react-bootstrap";
import { Project, Result } from "../../types/invoicingModel";

interface Props {
  result: Result;
  selectedProject: Project;
}

interface State {
  totalHours: number;
  billableHours: number;
  unbillableHours: number;
  averageBillableRate: number;
  totalAmount: number;
}

export default class ProjectHours extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      totalHours: 0,
      billableHours: 0,
      unbillableHours: 0,
      averageBillableRate: 0,
      totalAmount: 0,
    };

    this.calculateInfo = this.calculateInfo.bind(this);
  }

  async calculateInfo() {
    const { selectedProject } = this.props;

    if (!selectedProject) {
      return;
    }

    // convert the durations from milliseconds to hours
    const totalHours = selectedProject.duration / 3600000;
    const billableHours = selectedProject.billableDuration / 3600000;
    const unbillableHours = selectedProject.unbillableDuration / 3600000;

    // calculate the total billable amount and average billable rate
    let totalAmount = 0;
    let totalBillableAmount = 0;

    if (Array.isArray(selectedProject.billableAmount)) {
      selectedProject.billableAmount.forEach((bill) => {
        totalAmount += bill.amount;
      });
    } else {
      totalAmount = selectedProject.billableAmount;
    }

    totalBillableAmount = totalAmount;

    const averageBillableRate = totalBillableAmount / billableHours;

    this.setState({
      totalHours: totalHours,
      billableHours: billableHours,
      unbillableHours: unbillableHours,
      averageBillableRate: averageBillableRate,
      totalAmount: totalAmount,
    });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.selectedProject !== this.props.selectedProject) {
      this.calculateInfo();
    }
  }

  render() {
    return (
      <div>
        <Row className="mt-3">
          <Col md={2}>
            <FormLabel>Total Hours</FormLabel>
            <InputGroup>
              <FormControl
                value={this.state.totalHours.toFixed(2)}
                disabled={true}
              />
              <InputGroup.Text>hours</InputGroup.Text>
            </InputGroup>
          </Col>
          <Col md={2}>
            <FormLabel>Billable Hours</FormLabel>
            <InputGroup>
              <FormControl
                value={this.state.billableHours.toFixed(2)}
                disabled={true}
              />
              <InputGroup.Text>hours</InputGroup.Text>
            </InputGroup>
          </Col>
          <Col md={2}>
            <FormLabel>Unbillable Hours</FormLabel>
            <InputGroup>
              <FormControl
                value={this.state.unbillableHours.toFixed(2)}
                disabled={true}
              />
              <InputGroup.Text>hours</InputGroup.Text>
            </InputGroup>
          </Col>
          <Col md={2}>
            <FormLabel>Average Billable Rate</FormLabel>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <FormControl
                value={this.state.averageBillableRate.toFixed(2)}
                disabled={true}
              />
              <InputGroup.Text>hour</InputGroup.Text>
            </InputGroup>
          </Col>
          <Col md={2}>
            <FormLabel>Total Amount</FormLabel>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <FormControl
                value={this.state.totalAmount.toFixed(2)}
                disabled={true}
              />
            </InputGroup>
          </Col>
        </Row>
      </div>
    );
  }
}
