import React, { useEffect, useState } from 'react';
import { FormLabel, InputGroup } from 'react-bootstrap';
import { useTimeEntryStore } from '../context/TimeEntryContext';

interface NotesInputProps {
    disabled?: boolean;
    value?: string;
}

const NotesInput: React.FC<NotesInputProps> = ({ disabled, value }) => {
    const [inputValue, setInputValue] = useState<string>('');
    const { selectedTask, setNotes } = useTimeEntryStore();

    useEffect(() => {
        setInputValue(value || '');
    }, [value]);

    const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setInputValue(newValue);
        setNotes(newValue);
    };

    return (
        <>
            <FormLabel>Notes</FormLabel>
            <InputGroup>
                <input
                    className='form-control'
                    type='text'
                    id='notes'
                    key='notes'
                    name='notes'
                    onChange={onValueChange}
                    value={inputValue}
                    disabled={disabled || (selectedTask ? false : true)}
                />
            </InputGroup>
        </>
    );
}

export default NotesInput;
