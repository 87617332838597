import { faArrowRight, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Make sure to import this
import React from "react";
import { Button } from "react-bootstrap";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  normalIcon?: React.ReactNode;
  loadingIcon?: React.ReactNode;
  children: React.ReactNode;
}

const LoadingButton: React.FC<Props> = ({
  isLoading,
  normalIcon,
  loadingIcon,
  children,
  ...rest
}) => {
  return (
    <Button {...rest}>
      {children}
      <span className="ms-1">{isLoading ? loadingIcon : normalIcon}</span>
    </Button>
  );
};

LoadingButton.defaultProps = {
  normalIcon: (
    <span>
      <FontAwesomeIcon icon={faArrowRight} />
    </span>
  ),
  loadingIcon: (
    <span>
      <FontAwesomeIcon icon={faClock} spin />
    </span>
  ),
};

export default LoadingButton;
