import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';


interface Props {
  minHeight?: string;
}
interface State {
}
class LoadingSpinner extends Component<Props, State> {
  render() {
    const {minHeight} = this.props;
    return (
      <div className="d-flex justify-content-center align-items-center" style={{minHeight: minHeight}}>
        <FontAwesomeIcon icon={faSpinner} spin size="3x" />
      </div>
    );
  }
}

export default LoadingSpinner;
