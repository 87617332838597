
import React, {Component} from 'react';
import {Card} from 'react-bootstrap';

interface Props {
  children: React.ReactNode;
  title: string;
  buttonGroup?: React.ReactNode;
  buttonGroupLeft?: React.ReactNode;
}

interface State {}

class BaseCard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {children, title, buttonGroup, buttonGroupLeft} = this.props;

    return (
      <Card className="my-4">
        <Card.Header>
          <Card.Title>
            {buttonGroupLeft &&
                  <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                    <div className='pe-2'>
                      {buttonGroupLeft}
                    </div>
                    <div className='pe-2'>
                      {title}
                    </div>
                    <div className='pe-2'>
                      {buttonGroup}
                    </div>
                  </div>}
            {!buttonGroupLeft &&
                  <div className="d-flex justify-content-between align-items-center">
                    <div className='pe-2'>
                      {title}
                    </div>
                    <div className='pe-2'>
                      {buttonGroup}
                    </div>
                  </div>}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {children}
        </Card.Body>
      </Card>
    );
  }
}

export default BaseCard;
