import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect } from "react";
import { getLocations } from "../../../controller/tmetricController.js";
import { LocationType } from "../../../types/tmetric.js";
import MultiSelectDropdown from "../../base/multiselect.js";

interface Props {
  name: string;
  selectedLocation?: LocationType;
  selectedLocations?: LocationType[];
  onChangeMultiple?: (name: string, locations: LocationType[]) => void;
  onChangeSingle?: (name: string, location: LocationType) => void;
  multiple?: boolean;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  reloadTrigger?: number;
}

const LocationDropdown: React.FC<Props> = (props) => {
  const fetchLocations = useCallback(async () => {
    return await getLocations();
  }, []);

  const { data: locations, refetch } = useQuery<LocationType[], Error>({
    queryKey: ["locations"],
    queryFn: fetchLocations,
  });

  useEffect(() => {
    refetch();
  }, [props.reloadTrigger, refetch]);

  const onChange = useCallback(
    (name: string, locations: LocationType[]) => {
      if (props.onChangeMultiple) {
        props.onChangeMultiple(name, locations);
      }
      if (props.onChangeSingle) {
        props.onChangeSingle(name, locations[0]);
      }
    },
    [props]
  );

  return (
    <MultiSelectDropdown<LocationType>
      name={props.name}
      textField="name"
      loadItems={fetchLocations}
      options={locations ?? []}
      onChange={onChange}
      selectedItems={props.selectedLocations ?? [props.selectedLocation]}
      required={props.required}
      multiple={props.multiple}
      disabled={props.disabled}
    />
  );
};

export default LocationDropdown;
