import { TmetricHourType } from "../types/hour";
import { TmetricTimeEntriesResponseType } from "../types/tmetric";
import api from "../utils/api";
import { getMemberId } from "../utils/util";

export const startClock = async (hourType: {
  clientId: number,
  locationId: number,
  notes: string,
  projectId: number,
  taskId: string,
  workTypeId: number,
}): Promise<string> => {
  const tmetricHour: TmetricHourType = {
    clientId: hourType.clientId.toString(),
    locationId: hourType.locationId.toString(),
    notes: hourType.notes,
    projectId: hourType.projectId.toString(),
    memberId: getMemberId(),
    taskId: hourType.taskId.toString(),
    workTypeId: hourType.workTypeId.toString(),
    taskComplete: false,
  };
  try {
    await api.post("/hours/", tmetricHour);
    return "success";
  } catch (error) {
    console.error(error);
    return "failure";
  }
};

export const stopClock = async (): Promise<string> => {
  try {
    await api.delete(`/hours`, { data: { memberId: getMemberId() } });
    return "success";
  } catch (error) {
    console.error(error);
    return "failure";
  }
};

export const startClockFromTimeEntry = async (
  timeEntry: TmetricTimeEntriesResponseType,
): Promise<string> => {
  try {
    const data = {
      memberId: getMemberId(),
      ...timeEntry,
    };
    await api.post("/hours/fromTimeEntry", data);
    return "success";
  } catch (error) {
    console.error(error);
    return "failure";
  }
};
