import { ClickupProject, NewProjectType, Project } from "../types/project";
import api from "../utils/api";

export const getProjects = async (clientId: number): Promise<Project[]> => {
  const response = await api.get(`/clickup/clients/${clientId}/projects`);
  const clickupProjects: ClickupProject[] = response.data;
  return clickupProjects.map((clickupProject: ClickupProject) => {
    return {
      id: parseInt(clickupProject.clickUpId),
      clientId: parseInt(clickupProject.clickUpClientId),
      name: clickupProject.name,
    };
  });
};

export const createProject = async (newProject: NewProjectType): Promise<Project> => {
  try {
    const project = await api.post("/projects", newProject);
    return {
      id: parseInt(project.data.clickUpId),
      clientId: parseInt(project.data.clickUpClientId),
      name: project.data.name,
    }
  } catch (error) {
    console.error(error);
    throw new Error("Failed to create project");
  }
};
