import {
  faCamera,
  faRectangleList,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { openDB } from "idb";
import React, { Component } from "react";
import { Container, Form, FormLabel } from "react-bootstrap";
import PhotosClientDropdown from "../components/photos/PhotosClientDropdown";
import PhotosProjectDropdown from "../components/photos/PhotosProjectDropdown";
import { uploadFile } from "../controller/nextCloudPhotosController";
import { Client } from "../types/client";
import { Project } from "../types/project";

interface Props {}

interface State {
  clients: string[];
  projects: string[];
  client: Client;
  project: Project;
  loading: boolean;
  selectedImages: any[];
  reloadProjectsTrigger: number;
}

export default class NextCloudPhotosPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      clients: [],
      projects: [],
      client: undefined,
      project: undefined,
      loading: false,
      selectedImages: [],
      reloadProjectsTrigger: 0,
    };
  }

  componentDidMount() {
    this.loadImagesFromDB();
  }

  loadImagesFromDB = async () => {
    const db = await openDB("photos", 1, {
      upgrade(db) {
        db.createObjectStore("images");
      },
    });

    const tx = db.transaction("images", "readonly");
    const store = tx.objectStore("images");
    const images = await store.getAll();
    this.setState({ selectedImages: images });
  };

  onCaptureFromCamera = async (event: any) => {
    const files = event.target.files;
    const images = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const key = file.name + Date.now().toString(); // unique key
      images.push({ key, file, name: file.name });
    }
    this.setState(
      { selectedImages: [...this.state.selectedImages, ...images] },
      this.addImagesToDB
    );
  };
  onSelectFromGallery = async (event: any) => {
    const files = event.target.files;
    const images = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const key = file.name + Date.now().toString(); // unique key
      images.push({ key, file, name: file.name });
    }
    this.setState(
      { selectedImages: [...this.state.selectedImages, ...images] },
      this.addImagesToDB
    );
  };

  addImagesToDB = async () => {
    const db = await openDB("photos", 1, {
      upgrade(db) {
        db.createObjectStore("images");
      },
    });

    const tx = db.transaction("images", "readwrite");
    const store = tx.objectStore("images");
    for (let i = 0; i < this.state.selectedImages.length; i++) {
      const image = this.state.selectedImages[i];
      await store.put(image, image.key);
    }
    await tx.done;
  };

  onDeleteImage = async (key: string, event: any) => {
    event.preventDefault();
    const db = await openDB("photos", 1, {
      upgrade(db) {
        db.createObjectStore("images");
      },
    });

    const tx = db.transaction("images", "readwrite");
    const store = tx.objectStore("images");
    await store.delete(key);
    await tx.done;
    this.loadImagesFromDB();
  };

  onRenameImage = async (key: string, event: any) => {
    event.preventDefault();
    const newName = window.prompt(
      "Enter the new name for the image(Without file extension):"
    );
    if (newName === null || newName === "") {
      return;
    }
    const db = await openDB("photos", 1, {
      upgrade(db) {
        db.createObjectStore("images");
      },
    });

    const tx = db.transaction("images", "readwrite");
    const store = tx.objectStore("images");
    const image = await store.get(key);
    if (!image) {
      alert("Image not found in the database");
      return;
    }
    const newFileName = newName + "." + image.name.split(".").pop();
    const newFile = new File([image.file], newFileName, {
      type: image.file.type,
    });
    image.name = newFileName;
    image.file = newFile;
    await store.put(image, key);
    await tx.done;
    this.loadImagesFromDB();
  };

  clearImagesFromDB = async () => {
    const db = await openDB("photos", 1);
    const tx = db.transaction("images", "readwrite");
    const store = tx.objectStore("images");
    await store.clear();
    await tx.done;

    this.setState({ selectedImages: [] });
  };

  onSubmit = () => async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ loading: true });

    const db = await openDB("photos", 1);
    const tx = db.transaction("images", "readonly");
    const store = tx.objectStore("images");
    const images = await store.getAll();
    await tx.done;

    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      await uploadFile(image.file, this.state.client, this.state.project);
      console.log(image.file.name);
    }

    // clear the images in IndexedDB and state after they are all sent
    this.clearImagesFromDB();
    this.setState({ loading: false });
  };

  onSelectClient = (client: Client) => {
    this.setState({ client: client }, () => {
      this.setState({
        reloadProjectsTrigger: this.state.reloadProjectsTrigger + 1,
      });
    });
  };

  onSelecProjectFolder = (project: Project) => {
    this.setState({ project: project });
  };

  render() {
    return (
      <Container className="mt-3">
        <Form>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-4">
              <FormLabel>Client</FormLabel>
              <PhotosClientDropdown
                className=""
                onChangeSingle={this.onSelectClient}
                selectedClient={this.state.client}
                name={"client"}
              />
            </div>

            <div className="col-12 col-md-4">
              <FormLabel>Project Folder</FormLabel>
              <PhotosProjectDropdown
                className=""
                client={this.state.client}
                onChangeSingle={this.onSelecProjectFolder}
                selectedProject={this.state.project}
                name={"project"}
                reloadTrigger={this.state.reloadProjectsTrigger}
                disabled={!this.state.client}
              />
            </div>
            <div className="row d-flex justify-content-center align-items-centers">
              <div className="col-6 px-2 text-center mt-2">
                <label
                  htmlFor="captureFromCameraInput"
                  className="btn btn-primary"
                >
                  <FontAwesomeIcon icon={faCamera} className="ml-2 w-100" />
                  Camera
                </label>
                <input
                  id="captureFromCameraInput"
                  type="file"
                  accept="image/*"
                  capture="environment"
                  className="d-none"
                  onChange={this.onCaptureFromCamera}
                ></input>
              </div>
              <div className="col-6 px-2 text-center mt-2">
                <label
                  htmlFor="selectFromGalleryInput"
                  className="btn btn-primary"
                >
                  <FontAwesomeIcon
                    icon={faRectangleList}
                    className="ml-2 w-100"
                  />
                  Gallery
                </label>
                <input
                  id="selectFromGalleryInput"
                  type="file"
                  accept="image/*"
                  multiple
                  className="d-none"
                  onChange={this.onSelectFromGallery}
                ></input>
              </div>
            </div>

            <div className="container">
              <div className="row mt-2 list-group mb-5">
                {this.state.selectedImages.map((image, index) => (
                  <div className="list-group-item border-0 p-0" key={index}>
                    <div
                      className="row col-12 border m-1 p-1 rounded"
                      key={index}
                    >
                      <div className="col-4 d-flex">
                        <img
                          src={URL.createObjectURL(image.file)}
                          className="img-fluid rounded"
                          alt="..."
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }}
                        />
                      </div>

                      <div className="col-8 row m-0">
                        <div className="row d-flex align-content-center justify-content-center align-items-start m-0 p-0">
                          <h6 className="d-inline-block h6 text-truncate text-sm p-0">
                            {image.name.split(".").slice(0, -1).join(".")}
                          </h6>
                        </div>

                        <div className="row align-items-end m-0 p-0 justify-content-between mb-1">
                          <div className="col-6 m-0 p-0">
                            <button
                              className="btn btn-primary w-100"
                              onClick={(event) =>
                                this.onRenameImage(image.key, event)
                              }
                            >
                              Rename
                            </button>
                          </div>
                          <div className="col-4 m-0 p-0">
                            <button
                              className="btn btn-danger w-100"
                              onClick={(event) =>
                                this.onDeleteImage(image.key, event)
                              }
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="m-0 w-100"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="row d-flex justify-content-center align-items-center fixed-bottom m-0 bg-light pt-1">
              <div className="col-12 col-md-4 m-0">
                <button
                  className="btn btn-primary w-100 mb-1 w-full"
                  onClick={this.onSubmit()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Form>
        {this.state.loading && (
          <div className="photos-upload-dialog-show">
            <h1 className="photos-upload-dialog-text">Loading...</h1>
          </div>
        )}
      </Container>
    );
  }
}
