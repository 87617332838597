import moment from 'moment';
import numeral from 'numeral';
import React, { Component } from 'react';
import { Button, ButtonGroup, Row } from 'react-bootstrap';
import { Navigate, useParams } from 'react-router-dom';
import api from '../../utils/api.js';
import BaseCard from '../base/baseCard.js';
import NavigateBack from '../base/navigateBack.js';
import LoadingSpinner from '../loadingSpinner.js';
import EditInventoryItemModal from './editInventoryItemModal.js';
import { InventoryItem } from './inventoryItemType.js';
const url = '/inventoryItems';

interface Props {
  inventoryItemId: string;
}

interface State {
  inventoryItem: InventoryItem;
  loading: boolean;
  showEdit: boolean;
  submittedId: number;
}

class InventoryItemDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      inventoryItem: null,
      loading: true,
      showEdit: false,
      submittedId: null,
    };
  }

  async componentDidMount() {
    this.refreshInventoryItem();
  }

  refreshInventoryItem = async () => {
    this.setState({ loading: true });
    try {
      const { inventoryItemId } = this.props;
      const response = await api.get(`${url}/${inventoryItemId}`);
      const inventoryItem = response.data;
      this.setState({ inventoryItem, loading: false });
      document.title = inventoryItem.name ?? 'Inventory Item';
    } catch (error) {
      console.log(error);
      this.setState({ loading: false, inventoryItem: null });
    }
  };

  handleEdit = () => {
    this.setState({ showEdit: true });
  };

  handleEditCancel = () => {
    this.setState({ showEdit: false });
  };

  handleEditSubmit = () => {
    this.setState({ showEdit: false });
    this.refreshInventoryItem();
  };

  handleDropdownChange = (name: string, value: any) => {
    this.setState((prevState) => ({
      inventoryItem: {
        ...prevState.inventoryItem,
        [name]: value,
      },
    }));
  };

  render() {
    const { inventoryItem, loading, showEdit, submittedId } = this.state;

    if (submittedId) {
      return <Navigate to={`/products/${submittedId}`}
      />;
    }

    if (loading) {
      return <LoadingSpinner
        minHeight='100vh'
      />;
    }

    if (!inventoryItem) {
      return <NavigateBack />;
    }

    return (
      <div className='col'>
        <Row>
          <div className='col'>
            <BaseCard
              title={'Inventory Item: ' + inventoryItem.name}
              buttonGroup={<ButtonGroup>
                <Button variant="outline-secondary" onClick={this.handleEdit}>
                  Edit
                </Button>
              </ButtonGroup>}>
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="card">
                    <div className="card-header text-center">
                      <h4>Inventory Item Details</h4>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="text-muted">Name:</div>
                          <div>{inventoryItem.name}</div>
                        </div>
                        <div className="col-md-6">
                          <div className="text-muted">Description:</div>
                          <div>{inventoryItem.description}</div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="text-muted">Purchase Cost:</div>
                          <div>{numeral(inventoryItem.purchaseCost).format('$0,0.00')}</div>
                        </div>
                        <div className="col-md-6">
                          <div className="text-muted">Sales Price:</div>
                          <div>{numeral(inventoryItem.salesPrice).format('$0,0.00')}</div>
                        </div>
                      </div>
                      {/* More property rows can be added similarly */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="text-muted">Active:</div>
                          <div>{inventoryItem.isActive ? 'Yes' : 'No'}</div>
                        </div>
                        <div className="col-md-6">
                          <div className="text-muted">Quantity:</div>
                          <div>{inventoryItem.quantity}</div>
                        </div>
                      </div>
                      {/* More property rows can be added similarly */}
                      <div className="text-muted mt-3">Total Value:</div>
                      <div>{numeral(inventoryItem.quantity * inventoryItem.purchaseCost).format('$0,0.00')}</div>
                    </div>
                    <div className="card-footer text-muted text-center">
                      <div>Date Created: {moment(inventoryItem.dateCreated).format('YYYY-MM-DD HH:mm')}</div>
                      <div>Date Modified: {moment(inventoryItem.dateModified).format('YYYY-MM-DD HH:mm')}</div>
                    </div>
                  </div>
                </div>
              </div>
            </BaseCard>
          </div>
        </Row>
        <EditInventoryItemModal
          initialValues={{ id: inventoryItem.id }} show={showEdit}
          onSubmit={this.handleEditSubmit} onCancel={this.handleEditCancel}
        />
      </div>);
  }
}

const InventoryItemDetailWrapper = () => {
  const { id } = useParams();
  return <InventoryItemDetails inventoryItemId={id} />;
};

export default InventoryItemDetailWrapper;
export { InventoryItemDetails };

