import { PartsToBeBilledUploadInterface } from "../types/partsToBeBilled";
import api from "../utils/api";

export const uploadPartsToBeBilled = async (
  body: PartsToBeBilledUploadInterface
): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post(`/drive/partsToBeBilled`, body);
      resolve();
    } catch (error) {
      reject(error.response.data.error);
    }
  });
};
