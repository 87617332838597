import { faAdd, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { FormLabel, InputGroup } from "react-bootstrap";
import { Task } from "../../../types/task";
import { useTimeEntryStore } from "../context/TimeEntryContext";
import TaskDropdown from "../dropdowns/TasksDropdown";

export default function TaskInput() {
  const { selectedTask, selectedProject, setTask, setShowCreateTaskModal } =
    useTimeEntryStore();

  const onSelectTask = (name: string, task: Task) => {
    setTask(task);
  };

  const queryClient = useQueryClient();

  const onReloadTask = () => {
    queryClient.invalidateQueries(["tasks"]);
  };

  const onShowNewTaskModal = () => {
    setShowCreateTaskModal(true);
  };

  return (
    <>
      <FormLabel>Task</FormLabel>
      <InputGroup>
        <TaskDropdown
          selectedTask={selectedTask}
          className="col-12 col-md-6 col-lg-4"
          projectId={selectedProject?.id}
          name={"task"}
          disabled={selectedProject ? false : true}
          onChangeSingle={onSelectTask}
        />
        <button
          disabled={selectedProject ? false : true}
          className="btn btn-outline-secondary"
          onClick={onShowNewTaskModal}
        >
          <FontAwesomeIcon icon={faAdd}></FontAwesomeIcon>
        </button>
        <button
          disabled={selectedProject ? false : true}
          className="btn btn-outline-secondary"
          onClick={onReloadTask}
        >
          <FontAwesomeIcon icon={faRotateRight}></FontAwesomeIcon>
        </button>
      </InputGroup>
    </>
  );
}
