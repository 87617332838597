import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect } from "react";
import { getWorkTypes } from "../../../controller/tmetricController.js";
import { WorkType } from "../../../types/tmetric.js";
import MultiSelectDropdown from "../../base/multiselect.js";

interface Props {
  name: string;
  selectedWorkType?: WorkType;
  selectedWorkTypes?: WorkType[];
  onChangeMultiple?: (name: string, workTypes: WorkType[]) => void;
  onChangeSingle?: (name: string, workType: WorkType) => void;
  multiple?: boolean;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  projectId?: number;
}

const WorkTypeDropdown: React.FC<Props> = (props) => {
  const fetchWorkTypes = useCallback(async () => {
    if (!props.projectId) {
      return [];
    }
    return await getWorkTypes(props.projectId);
  }, [props.projectId]);

  const { data: workTypes, refetch } = useQuery<WorkType[], Error>({
    queryKey: ["workTypes", props.projectId],
    queryFn: fetchWorkTypes,
    enabled: !!props.projectId,
  });

  useEffect(() => {
    refetch();
  }, [props.projectId, refetch]);

  const onChange = useCallback(
    (name: string, workTypes: WorkType[]) => {
      if (props.onChangeMultiple) {
        props.onChangeMultiple(name, workTypes);
      }
      if (props.onChangeSingle) {
        props.onChangeSingle(name, workTypes[0]);
      }
    },
    [props]
  );

  return (
    <MultiSelectDropdown<WorkType>
      name={props.name}
      textField="name"
      loadItems={fetchWorkTypes}
      options={workTypes ?? []}
      onChange={onChange}
      selectedItems={props.selectedWorkTypes ?? [props.selectedWorkType]}
      required={props.required}
      multiple={props.multiple}
      disabled={props.disabled}
    />
  );
};

export default WorkTypeDropdown;
