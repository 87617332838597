import React from "react";
import { ListGroup } from "react-bootstrap";
import { useTimeDisplayStore } from "../context/TimeDisplayContext";
import BreakEntry from "./BreakEntry";
import TimeEntry from "./TimeEntry";

function TimeEntries() {
  const { timeEntries } = useTimeDisplayStore();

  return (
    <ListGroup className="m-0 p-0">
      {timeEntries.map((timeEntry) =>
        timeEntry.isBreak ? (
          <BreakEntry key={timeEntry.timeEntryId} timeEntry={timeEntry} />
        ) : (
          <TimeEntry key={timeEntry.timeEntryId} timeEntry={timeEntry} />
        )
      )}
    </ListGroup>
  );
}

export default TimeEntries;
