import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import z from "zod";
import { createClient } from "../../../controller/clientController";
import { Client } from "../../../types/client";
import LoadingButton from "../../base/LoadingButton";

const schema = z.object({
  clientName: z.string().min(1),
});

interface NewClient {
  name: string;
}

interface Props {
  show: boolean;
  onClose: () => void;
  onSuccess: (client: Client) => void;
}

export default function CreateClientModal({ show, onClose, onSuccess }: Props) {
  const { register, handleSubmit, reset } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newClient: NewClient) => createClient(newClient),
    {
      onSuccess: (client: Client) => {
        queryClient.invalidateQueries(["clients"]);
        onSuccess(client);
        onClose();
        reset();
      },
      onError: (error: any) => {
        toast.error(error.message);
      },
    }
  );

  async function handleCreate(data: z.infer<typeof schema>) {
    const newClient = {
      name: data.clientName,
    };
    mutation.mutate(newClient);
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Form onSubmit={handleSubmit(handleCreate)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Client Name"
              {...register("clientName")}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton isLoading={mutation.isLoading} type="submit">
            Create
          </LoadingButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
