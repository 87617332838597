import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { startClock } from "../../../controller/hoursController";
import { useTimeDisplayStore } from "../../timedisplay/context/TimeDisplayContext";
import { useTimeEntryStore } from "../context/TimeEntryContext";

export default function StartButton() {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { reload } = useTimeDisplayStore();
  const {
    selectedClient,
    selectedProject,
    selectedTask,
    selectedWorkType,
    selectedLocation,
    notes,
  } = useTimeEntryStore();

  useEffect(() => {
    if (
      selectedClient &&
      selectedProject &&
      selectedTask &&
      selectedWorkType &&
      selectedLocation
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    selectedClient,
    selectedProject,
    selectedTask,
    selectedWorkType,
    selectedLocation,
  ]);

  const onStartClock = async () => {
    const entry = {
      clientId: selectedClient?.id,
      projectId: selectedProject?.id,
      taskId: selectedTask?.id,
      workTypeId: selectedWorkType?.id,
      locationId: selectedLocation?.id,
      notes: notes,
    };
    setLoading(true);
    await startClock(entry);
    setLoading(false);
    reload();
  };

  return (
    <Button
      size="lg"
      disabled={disabled}
      variant="primary"
      onClick={onStartClock}
    >
      Start <FontAwesomeIcon className="ms-1" icon={faClock} spin={loading} />
    </Button>
  );
}
