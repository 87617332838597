import {
  LocationType,
  TmetricLocationType,
  TmetricTimeEntriesResponseType,
  TmetricTimerResponseStarted,
  TmetricWorkType,
  UpdateTimeEntryType,
  WorkType,
} from "../types/tmetric";
import api from "../utils/api";
import { getMemberId } from "../utils/util";

export const getWorkTypes = async (projectId: number): Promise<WorkType[]> => {
  const response = await api.get(
    `/tmetric/workTypes?clickUpProjectId=${projectId}`
  );
  const tmetricWorkTypes: TmetricWorkType[] = response.data;
  return tmetricWorkTypes.map((tmetricWorkType: TmetricWorkType) => {
    return {
      id: parseInt(tmetricWorkType.tagId),
      name: tmetricWorkType.tagName,
      billable: tmetricWorkType.defaultBillableRate.amount > 0,
      rate: tmetricWorkType.defaultBillableRate.amount,
    };
  });
};

export const getAllWorkTypes = async (): Promise<WorkType[]> => {
  const response = await api.get(`/tmetric/workTypes`);
  const tmetricWorkTypes: TmetricWorkType[] = response.data;
  return tmetricWorkTypes.map((tmetricWorkType: TmetricWorkType) => {
    return {
      id: parseInt(tmetricWorkType.tagId),
      name: tmetricWorkType.tagName,
      billable: tmetricWorkType.defaultBillableRate.amount > 0,
      rate: tmetricWorkType.defaultBillableRate.amount,
    };
  });
};

export const getLocations = async (): Promise<LocationType[]> => {
  const response = await api.get(`/tmetric/locations`);
  const tmetricLocationTypes: TmetricLocationType[] = response.data;
  return tmetricLocationTypes.map(
    (tmetricLocationType: TmetricLocationType) => {
      return {
        id: parseInt(tmetricLocationType.tagId),
        name: tmetricLocationType.tagName,
      };
    }
  );
};

export const getTimer = (): Promise<TmetricTimerResponseStarted> => {
  return new Promise((resolve, reject) => {
    api
      .get(`/tmetric/timer?userId=${getMemberId()}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const insertBreaks = (
  timeEntries: TmetricTimeEntriesResponseType[]
): TmetricTimeEntriesResponseType[] => {
  const newTimeEntries: TmetricTimeEntriesResponseType[] = [];
  let lastTimeEntry: TmetricTimeEntriesResponseType | undefined = undefined;
  for (const timeEntry of timeEntries) {
    if (lastTimeEntry) {
      const lastTimeEntryEndDate = new Date(lastTimeEntry.endTime);
      const timeEntryDate = new Date(timeEntry.startTime);

      if (lastTimeEntryEndDate.getTime() !== timeEntryDate.getTime()) {
        if (lastTimeEntryEndDate.getTime() < timeEntryDate.getTime()) {
          const breakTimeEntry: TmetricTimeEntriesResponseType = {
            isBreak: true,
            isBillable: false,
            startTime: lastTimeEntry.endTime,
            isInvoiced: false,
            endTime: timeEntry.startTime,
            projectName: "",
            timeEntryId: 0,
            tagsIdentifiers: [],
            details: {
              description: "Break",
              projectId: 0,
              projectTask: {
                assigneeId: 0,
                creatorId: 0,
                description: "",
                externalIssueId: "",
                showIssueId: false,
                integrationId: 0,
                integrationUrl: "",
                relativeIssueUrl: "",
                projectId: 0,
                projectTaskId: 0,
                isCompleted: true,
              },
            },
          };
          newTimeEntries.push(breakTimeEntry);
        } else {
          console.warn(
            "Time entry is overlapping with last time entry, break cannot be calculated"
          );
        }
      }
    }
    newTimeEntries.push(timeEntry);
    lastTimeEntry = timeEntry;
  }
  return newTimeEntries;
};

const sortTimeEntries = (
  timeEntries: TmetricTimeEntriesResponseType[]
): TmetricTimeEntriesResponseType[] => {
  return timeEntries.sort(
    (a: TmetricTimeEntriesResponseType, b: TmetricTimeEntriesResponseType) => {
      const aDate = new Date(a.startTime);
      const bDate = new Date(b.startTime);
      return aDate.getTime() - bDate.getTime();
    }
  );
};

export const getTimeEntries = (
  startTime: string,
  endTime: string
): Promise<TmetricTimeEntriesResponseType[]> => {
  return new Promise((resolve, reject) => {
    api
      .get(`/tmetric/timeEntries`, {
        params: {
          userId: getMemberId(),
          startDate: startTime,
          endDate: endTime,
        },
      })
      .then((response) => {
        const timeEntries: TmetricTimeEntriesResponseType[] = response.data;
        const sortedTimeEntries = sortTimeEntries(timeEntries);
        resolve(insertBreaks(sortedTimeEntries));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

function convertTmetricTimeEntriesResponseType(
  input: TmetricTimeEntriesResponseType
): UpdateTimeEntryType {
  return {
    timeEntryId: input.timeEntryId,
    project: {
      id: input.details.projectId,
    },
    task: {
      id: input.details.projectTask.projectTaskId,
      name: input.projectName,
      externalLink: {
        caption: input.details.projectTask.externalIssueId,
        iconUrl: null, // iconUrl is not available in TmetricTimeEntriesResponseType
        link: input.details.projectTask.relativeIssueUrl,
        issueId: input.details.projectTask.externalIssueId,
      },
      integration: {
        url: input.details.projectTask?.integrationUrl,
        type: null, // type is not available in TmetricTimeEntriesResponseType
      },
    },
    note: input.details.description,
    tags: input.tagsIdentifiers.map((tag) => ({
      id: tag,
      name: null, // name is not available in TmetricTimeEntriesResponseType
      isWorkType: null, // isWorkType is not available in TmetricTimeEntriesResponseType
    })),
    startTime: input.startTime,
    endTime: input.endTime,
  };
}

export const updateTimeEntry = async (
  timeEntry: TmetricTimeEntriesResponseType,
  newStartTime: string,
  newEndTime: string,
  wasRunning: boolean
): Promise<boolean> => {
  delete timeEntry.isBreak;
  const oldStartTime = timeEntry.startTime;
  const oldEndTime = timeEntry.endTime;
  timeEntry.startTime = newStartTime;
  timeEntry.endTime = newEndTime;
  console.log(newStartTime, newEndTime);
  const body = {
    timeEntry: {
      ...timeEntry,
      details: {
        ...timeEntry.details,
      },
    },
    isStarted: wasRunning,
    oldStartTime: oldStartTime,
    oldEndTime: oldEndTime,
  };
  const response = await api.put(
    `/tmetric/timeentries?creatorId=${localStorage.getItem("userprofileId")}`,
    body
  );
  return response.status === 200;
};

export const addBreak = async (
  startTime: string,
  endTime: string
): Promise<boolean> => {
  const memberId = getMemberId();

  const body = {
    startTime: startTime,
    endTime: endTime,
    memberId: memberId,
  };
  const response = await api.post(
    `/tmetric/timeentries/break?memberId=${memberId}`,
    body
  );
  return response.status === 200;
};
