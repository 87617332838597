import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect } from "react";
import { getProjects } from "../../../controller/projectController.js";
import { Project } from "../../../types/project.js";
import MultiSelectDropdown from "../../base/multiselect.js";

interface Props {
  name: string;
  selectedProject?: Project;
  selectedProjects?: Project[];
  onChangeMultiple?: (name: string, projects: Project[]) => void;
  onChangeSingle?: (name: string, project: Project) => void;
  multiple?: boolean;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  clientId?: number;
}

const ProjectDropdown: React.FC<Props> = (props) => {
  const fetchProjects = useCallback(async () => {
    if (!props.clientId) {
      return [];
    }
    return await getProjects(props.clientId);
  }, [props.clientId]);

  const { data: projects, refetch } = useQuery<Project[], Error>({
    queryKey: ["projects", props.clientId],
    queryFn: fetchProjects,
    enabled: !!props.clientId,
  });

  useEffect(() => {
    refetch();
  }, [props.clientId, refetch]);

  const onChange = useCallback(
    (name: string, projects: Project[]) => {
      if (props.onChangeMultiple) {
        props.onChangeMultiple(name, projects);
      }
      if (props.onChangeSingle) {
        props.onChangeSingle(name, projects[0]);
      }
    },
    [props]
  );

  return (
    <MultiSelectDropdown<Project>
      name={props.name}
      textField="name"
      loadItems={fetchProjects}
      options={projects ?? []}
      onChange={onChange}
      selectedItems={props.selectedProjects ?? [props.selectedProject]}
      required={props.required}
      multiple={props.multiple}
      disabled={props.disabled}
    />
  );
};

export default ProjectDropdown;
