import { ProjectFolderType } from "../types/project";
import api from "../utils/api";

export const getProjectFolders = async (
  clientName: string
): Promise<ProjectFolderType[]> => {
  try {
    const response = await api.get(`/drive/folders/${clientName}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
