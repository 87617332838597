import { Row } from "react-bootstrap";

import React, { useEffect } from "react";
import { useTimeDisplayStore } from "./context/TimeDisplayContext";
import { useFetchTimeEntries } from "./hooks/useFetchTimeEntries";
import { useFetchTimer } from "./hooks/useFetchTimer";
import TimeEntries from "./timeentries/TimeEntries";
import Topbar from "./topbar/Topbar";

export default function TimeDisplay() {
  const { reload } = useTimeDisplayStore();
  useFetchTimeEntries();
  useFetchTimer();

  useEffect(() => {
    reload();
  }, [reload]);

  return (
    <div>
      <Row>
        <Topbar />
      </Row>
      <Row className="mt-2">
        <TimeEntries />
      </Row>
    </div>
  );
}
