import moment from "moment";
import { useEffect } from "react";
import { getTimeEntries } from "../../../controller/tmetricController";
import { useTimeDisplayStore } from "../context/TimeDisplayContext";

export function useFetchTimeEntries() {
  const { date, reloadTrigger, setTimeEntriesLoading, setTimeEntries, setTotalHours } = useTimeDisplayStore();

  useEffect(() => {
    async function fetchTimeEntries() {
      setTimeEntriesLoading(true);
      const timeEntries = await getTimeEntries(
        date,
        moment(date).add(1, "day").format("YYYY-MM-DDT00:00:00")
      );
      let totalHours = 0;
      timeEntries.forEach((timeEntry) => {
        if (timeEntry.isBreak) return;
        const { startTime, endTime } = timeEntry;
        const start = new Date(startTime);
        const end = endTime ? new Date(endTime) : new Date();
        const durationMs = end.getTime() - start.getTime();
        const hours = Math.floor(durationMs / 3600000);
        const minutes = Math.floor((durationMs % 3600000) / 60000);
        totalHours += hours + minutes / 60;
      });
      setTimeEntries(timeEntries);
      setTotalHours(totalHours);
      setTimeEntriesLoading(false);
    }

    fetchTimeEntries();
  }, [date, reloadTrigger, setTimeEntries, setTimeEntriesLoading, setTotalHours]);
}
