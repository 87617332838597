import { faAdd, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { FormLabel, InputGroup } from "react-bootstrap";
import { Project } from "../../../types/project";
import { useTimeEntryStore } from "../context/TimeEntryContext";
import ProjectDropdown from "../dropdowns/ProjectDropdown";

export default function ProjectInput() {
  const {
    selectedProject,
    selectedClient,
    setProject,
    setShowCreateProjectModal,
  } = useTimeEntryStore();

  const onSelectProject = (name: string, project: Project) => {
    setProject(project);
  };

  const queryClient = useQueryClient();

  const onReloadProject = () => {
    queryClient.invalidateQueries(["projects"]);
  };

  const onShowNewProjectModal = () => {
    setShowCreateProjectModal(true);
  };

  return (
    <>
      <FormLabel>Project</FormLabel>
      <InputGroup>
        <ProjectDropdown
          className="col-12 col-md-6 col-lg-4"
          selectedProject={selectedProject}
          onChangeSingle={onSelectProject}
          clientId={selectedClient?.id}
          name={"project"}
          disabled={selectedClient ? false : true}
        />
        <button
          disabled={selectedClient ? false : true}
          className="btn btn-outline-secondary"
          onClick={onShowNewProjectModal}
        >
          <FontAwesomeIcon icon={faAdd}></FontAwesomeIcon>
        </button>
        <button
          disabled={selectedClient ? false : true}
          className="btn btn-outline-secondary"
          onClick={onReloadProject}
        >
          <FontAwesomeIcon icon={faRotateRight}></FontAwesomeIcon>
        </button>
      </InputGroup>
    </>
  );
}
