import {
  faCamera,
  faRectangleList,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { openDB } from "idb";
import React, { Component } from "react";
import { Container, Form, FormLabel } from "react-bootstrap";
import ProjectFolderDopdown from "../components/photos/ProjectFolderDropdown";
import ClientDropdown from "../components/timeentry/dropdowns/ClientDropdown";
import { Client } from "../types/client";
import { ProjectFolderType } from "../types/project";
import api from "../utils/api";

interface Props { }

interface State {
  client: Client;
  clientName: string;
  selectedProjectFolder: ProjectFolderType;
  selectedImages: any[];
  selectedProjectFolderPhotoFolderId: string;
  loading: boolean;
}

export default class PhotosPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      client: null,
      clientName: null,
      selectedProjectFolder: null,
      selectedImages: [],
      selectedProjectFolderPhotoFolderId: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.loadImagesFromDB();
  }

  loadImagesFromDB = async () => {
    const db = await openDB("photos", 1, {
      upgrade(db) {
        db.createObjectStore("images");
      },
    });

    const tx = db.transaction("images", "readonly");
    const store = tx.objectStore("images");
    const images = await store.getAll();
    this.setState({ selectedImages: images });
  };

  onCaptureFromCamera = async (event: any) => {
    const files = event.target.files;
    const images = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const key = file.name + Date.now().toString(); // unique key
      images.push({ key, file, name: file.name });
    }
    this.setState(
      { selectedImages: [...this.state.selectedImages, ...images] },
      this.addImagesToDB
    );
  };
  onSelectFromGallery = async (event: any) => {
    const files = event.target.files;
    const images = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const key = file.name + Date.now().toString(); // unique key
      images.push({ key, file, name: file.name });
    }
    this.setState(
      { selectedImages: [...this.state.selectedImages, ...images] },
      this.addImagesToDB
    );
  };

  addImagesToDB = async () => {
    const db = await openDB("photos", 1, {
      upgrade(db) {
        db.createObjectStore("images");
      },
    });

    const tx = db.transaction("images", "readwrite");
    const store = tx.objectStore("images");
    for (let i = 0; i < this.state.selectedImages.length; i++) {
      const image = this.state.selectedImages[i];
      await store.put(image, image.key);
    }
    await tx.done;
  };

  onDeleteImage = async (key: string, event: any) => {
    event.preventDefault();
    const db = await openDB("photos", 1, {
      upgrade(db) {
        db.createObjectStore("images");
      },
    });

    const tx = db.transaction("images", "readwrite");
    const store = tx.objectStore("images");
    await store.delete(key);
    await tx.done;
    this.loadImagesFromDB();
  };

  onRenameImage = async (key: string, event: any) => {
    event.preventDefault();
    const newName = window.prompt(
      "Enter the new name for the image(Without file extension):"
    );
    if (newName === null || newName === "") {
      return;
    }
    const db = await openDB("photos", 1, {
      upgrade(db) {
        db.createObjectStore("images");
      },
    });

    const tx = db.transaction("images", "readwrite");
    const store = tx.objectStore("images");
    const image = await store.get(key);
    if (!image) {
      alert("Image not found in the database");
      return;
    }
    image.name = newName + "." + image.name.split(".").pop();
    await store.put(image, key);
    await tx.done;
    this.loadImagesFromDB();
  };

  submitPhotos = async (event: any) => {
    event.preventDefault();

    if (!this.state.selectedProjectFolder) {
      alert("Please select a project folder");
      return;
    }

    if (this.state.selectedImages.length === 0) {
      alert("Please select at least one image");
      return;
    }

    this.setState({ loading: true });

    const response = await api.get(
      "/drive/photos/" + this.state.selectedProjectFolder.id
    );
    if (response.status !== 200) {
      alert("Error getting photos folder");
      this.setState({ loading: false });
      return;
    }
    const photosFolder = response.data;
    this.setState({ selectedProjectFolderPhotoFolderId: photosFolder.id });

    const db = await openDB("photos", 1);
    const tx = db.transaction("images", "readonly");
    const store = tx.objectStore("images");
    const images = await store.getAll();
    await tx.done;

    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      // convert File to FormData to send to the server
      let formData = new FormData();
      formData.append("files", image.file, image.name);
      const response = await api.post(
        "/drive/photos/upload/" + this.state.selectedProjectFolderPhotoFolderId,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status !== 200) {
        alert("Error uploading image: " + image.name);
        this.setState({ loading: false });
        return;
      }
    }

    // clear the images in IndexedDB and state after they are all sent
    this.clearImagesFromDB();
    this.setState({ loading: false });
  };

  clearImagesFromDB = async () => {
    const db = await openDB("photos", 1);
    const tx = db.transaction("images", "readwrite");
    const store = tx.objectStore("images");
    await store.clear();
    await tx.done;

    this.setState({ selectedImages: [] });
  };

  onSelectClient = (name: string, client: Client) => {
    this.setState({ client: client });
    this.setState({ clientName: client.name });
  };

  onSelecProjectFolder = (name: string, projectFolder: ProjectFolderType) => {
    this.setState({ selectedProjectFolder: projectFolder });
  };

  render() {
    return (
      <Container className="mt-3">
        <Form>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-4">
              <FormLabel>Client</FormLabel>
              <ClientDropdown
                className=""
                onChangeSingle={this.onSelectClient}
                selectedClient={this.state.client}
                name={"client"}
              />
            </div>

            <div className="col-12 col-md-4">
              <FormLabel>Project Folder</FormLabel>
              <ProjectFolderDopdown
                clientName={this.state.clientName}
                selectedProjectFolder={this.state.selectedProjectFolder}
                disabled={this.state.clientName ? false : true}
                onChangeSingle={this.onSelecProjectFolder}
                required={true}
              />
            </div>
            <div className="row d-flex justify-content-center align-items-centers">
              <div className="col-6 px-2 text-center mt-2">
                <label
                  htmlFor="captureFromCameraInput"
                  className="btn btn-primary"
                >
                  <FontAwesomeIcon icon={faCamera} className="ml-2 w-100" />
                  Camera
                </label>
                <input
                  id="captureFromCameraInput"
                  type="file"
                  accept="image/*"
                  capture="environment"
                  className="d-none"
                  onChange={this.onCaptureFromCamera}
                ></input>
              </div>
              <div className="col-6 px-2 text-center mt-2">
                <label
                  htmlFor="selectFromGalleryInput"
                  className="btn btn-primary"
                >
                  <FontAwesomeIcon
                    icon={faRectangleList}
                    className="ml-2 w-100"
                  />
                  Gallery
                </label>
                <input
                  id="selectFromGalleryInput"
                  type="file"
                  accept="image/*"
                  multiple
                  className="d-none"
                  onChange={this.onSelectFromGallery}
                ></input>
              </div>
            </div>

            <div className="container">
              <div className="row mt-2 list-group mb-5">
                {this.state.selectedImages.map((image, index) => (
                  <div className="list-group-item border-0 p-0">
                    <div
                      className="row col-12 border m-1 p-1 rounded"
                      key={index}
                    >
                      <div className="col-4 d-flex">
                        <img
                          src={URL.createObjectURL(image.file)}
                          className="img-fluid rounded"
                          alt="..."
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }}
                        />
                      </div>

                      <div className="col-8 row m-0">
                        <div className="row d-flex align-content-center justify-content-center align-items-start m-0 p-0">
                          <h6 className="d-inline-block h6 text-truncate text-sm p-0">
                            {image.name.split(".").slice(0, -1).join(".")}
                          </h6>
                        </div>

                        <div className="row align-items-end m-0 p-0 justify-content-between mb-1">
                          <div className="col-6 m-0 p-0">
                            <button
                              className="btn btn-primary w-100"
                              onClick={(event) =>
                                this.onRenameImage(image.key, event)
                              }
                            >
                              Rename
                            </button>
                          </div>
                          <div className="col-4 m-0 p-0">
                            <button
                              className="btn btn-danger w-100"
                              onClick={(event) =>
                                this.onDeleteImage(image.key, event)
                              }
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="m-0 w-100"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="row d-flex justify-content-center align-items-center fixed-bottom m-0 bg-light pt-1">
              <div className="col-12 col-md-4 m-0">
                <button
                  className="btn btn-primary w-100 mb-1 w-full"
                  onClick={(event) => this.submitPhotos(event)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Form>
        {this.state.loading && (
          <div className="photos-upload-dialog-show">
            <h1 className="photos-upload-dialog-text">Loading...</h1>
          </div>
        )}
      </Container>
    );
  }
}
