import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Client } from "../../types/client";
import { Project } from "../../types/project";
import { Task } from "../../types/task";
import { useTimeEntryStore } from "./context/TimeEntryContext";
import ClientInput from "./inputs/ClientInput";
import LocationInput from "./inputs/LocationInput";
import NotesInput from "./inputs/NotesInput";
import ProjectInput from "./inputs/ProjectInput";
import StartButton from "./inputs/StartButton";
import TaskInput from "./inputs/TaskInput";
import WorkTypeInput from "./inputs/WorkTypeInput";
import CreateClientModal from "./modals/CreateClientModal";
import CreateProjectModal from "./modals/CreateProjectModal";
import CreateTaskModal from "./modals/CreateTaskModal";

export default function TimeEntry() {
  const {
    showCreateClientModal,
    showCreateProjectModal,
    showCreateTaskModal,
    setShowCreateClientModal,
    setShowCreateProjectModal,
    setShowCreateTaskModal,
    setClient,
    setProject,
    setTask,
  } = useTimeEntryStore();

  return (
    <Container className="mt-3">
      <Row>
        <Col xs={12} md={6} lg={4} className="mb-3">
          <ClientInput />
        </Col>

        <Col xs={12} md={6} lg={4} className="mb-3">
          <ProjectInput />
        </Col>

        <Col xs={12} md={6} lg={4} className="mb-3">
          <TaskInput />
        </Col>

        <Col xs={12} md={6} lg={4} className="mb-3">
          <WorkTypeInput />
        </Col>

        <Col xs={12} md={6} lg={4} className="mb-3">
          <LocationInput />
        </Col>

        <Col xs={12} className="mb-3">
          <NotesInput />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end">
          <StartButton />
        </Col>
      </Row>

      <CreateClientModal
        show={showCreateClientModal}
        onClose={() => setShowCreateClientModal(false)}
        onSuccess={(client: Client) => setClient(client)}
      />

      <CreateProjectModal
        show={showCreateProjectModal}
        onClose={() => setShowCreateProjectModal(false)}
        onSuccess={(project: Project) => setProject(project)}
      />

      <CreateTaskModal
        show={showCreateTaskModal}
        onClose={() => setShowCreateTaskModal(false)}
        onSuccess={(task: Task) => setTask(task)}
      />
    </Container>
  );
}
