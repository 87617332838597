import { faArrowRight, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { addBreak } from "../../../controller/tmetricController";
import { useTimeDisplayStore } from "../context/TimeDisplayContext";

interface Props {
  show: boolean;
  onHide: () => void;
}

function AddBreakModal({ show, onHide }: Props) {
  const [startTime, setStartTime] = useState(moment().format("HH:mm"));
  const [endTime, setEndTime] = useState(moment().format("HH:mm"));
  const [loading, setLoading] = useState(false);
  const { reload } = useTimeDisplayStore();

  async function handleSave() {
    setLoading(true);
    await addBreak(startTime, endTime);
    setLoading(false);
    onHide();
    reload();
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Break</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-3">
          <Col xs={5} className="d-flex items-center justify-content-center">
            <div>
              <h6>Start Time</h6>
              <input
                type="time"
                value={startTime}
                onChange={(e: any) => {
                  setStartTime(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col
            xs={2}
            className="d-flex justify-content-center align-items-center"
          >
            <FontAwesomeIcon icon={faArrowRight} size="2x" />
          </Col>
          <Col xs={5} className="d-flex items-center justify-content-center">
            <div>
              <h6>End Time</h6>
              <input
                type="time"
                value={endTime}
                onChange={(e: any) => {
                  setEndTime(e.target.value);
                }}
              />
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Done
          {loading && (
            <FontAwesomeIcon
              icon={faClock}
              className="ms-2 fa-spin"
            ></FontAwesomeIcon>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddBreakModal;
