import { useQuery } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { getClients } from "../../../controller/clientController.js";
import { Client } from "../../../types/client.js";
import MultiSelectDropdown from "../../base/multiselect.js";

interface Props {
  name: string;
  label?: string;
  selectedClient?: Client;
  selectedClients?: Client[];
  onChangeMultiple?: (name: string, clients: Client[]) => void;
  onChangeSingle?: (name: string, client: Client) => void;
  multiple?: boolean;
  required?: boolean;
  className?: string;
  disabled?: boolean;
}

const ClientDropdown: React.FC<Props> = (props) => {
  const fetchClients = useCallback(async () => {
    return await getClients();
  }, []);

  const { data: clients } = useQuery<Client[], Error>({
    queryKey: ["clients"],
    queryFn: fetchClients,
  });

  const onChange = useCallback(
    (name: string, clients: Client[]) => {
      if (props.onChangeMultiple) {
        props.onChangeMultiple(name, clients);
      }
      if (props.onChangeSingle) {
        props.onChangeSingle(name, clients[0]);
      }
    },
    [props]
  );

  return (
    <MultiSelectDropdown<Client>
      name={props.name}
      textField="name"
      loadItems={fetchClients}
      options={clients ?? []}
      onChange={onChange}
      selectedItems={props.selectedClients ?? [props.selectedClient]}
      required={props.required}
      multiple={props.multiple}
      disabled={props.disabled}
    />
  );
};

export default ClientDropdown;
