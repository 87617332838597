import {
  CellContext,
  ColumnDef,
  ColumnDefTemplate,
  ColumnFiltersState,
  FilterFn,
  FilterFnOption,
  Row,
  SortingFnOption,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';

import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faSort, faSortDown, faSortUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  RankingInfo,
} from '@tanstack/match-sorter-utils';
import { ColumnSelector, Filter, fuzzyFilter, fuzzySort } from '../../utils/tableUtils.js';
import './baseTable.css';
import DebouncedInput from './debouncedInput.js';

declare module '@tanstack/table-core' {
  // eslint-disable-next-line no-unused-vars
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  // eslint-disable-next-line no-unused-vars
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

export interface ColumnProps<T> {
  header: string;
  id?: string;
  visible?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  accessorKey?: keyof T;
  accessorFn?: (row: T) => unknown;
  cell?: ColumnDefTemplate<CellContext<T, unknown>>;
  footer?: (props: { column: ColumnDef<T>; }) => React.ReactNode;
  filterFn?: FilterFnOption<T>;
  sortingFn?: SortingFnOption<T>;
  filterMethod?: (filter: string, row: T) => boolean;
}

export interface TableProps<T> {
  columnVisibility?: boolean;
  globalFilter?: boolean;
  minimalFormatMinRows?: number;
  data: T[];
  columns: ColumnProps<T>[];
  rowProps?: (row: Row<T>) => React.HTMLProps<HTMLTableRowElement>;
  defaultSort?: { id: string; desc: boolean; };
  paging?: boolean;
}

function getColumnDefs<T>(columns: ColumnProps<T>[], minimalView: boolean): ColumnDef<T>[] {
  return columns.map((column) => {
    const columnDef: ColumnDef<T> = {
      header: column.header,
      enableSorting: column.sortable !== false,
      enableColumnFilter: !minimalView && column.filterable !== false,
      sortingFn: column.sortingFn ?? fuzzySort,
    };
    if (column.accessorKey) (columnDef as any).accessorKey = column.accessorKey;
    if (column.accessorFn) (columnDef as any).accessorFn = column.accessorFn;
    if (column.filterFn) columnDef.filterFn = column.filterFn;
    if (column.cell) columnDef.cell = column.cell;
    if (column.footer) columnDef.footer = column.footer;
    return columnDef;
  });
}

function BaseTable<T>(props: TableProps<T>) {
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const [globalFilter, setGlobalFilter] = React.useState('');

  const minimalView = props.data?.length <= (props.minimalFormatMinRows ?? 0);

  const table = useReactTable({
    data: props.data,
    columns: getColumnDefs(props.columns, minimalView),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
    ...!minimalView && {
      getPaginationRowModel: (props.paging == false ? undefined : getPaginationRowModel()),
      filterFns: {
        fuzzy: fuzzyFilter,
      },
      state: {
        columnFilters,
        globalFilter,
      },
    },
    initialState: {
      sorting: props.defaultSort ? [
        props.defaultSort,
      ] : undefined,
      pagination: {
        pageSize: 20,
      },
    },
  });

  const initializedRef = React.useRef(false);
  if (!initializedRef.current) {
    table.getAllLeafColumns().forEach((column) => {
      const columnDef = props.columns.find((c) => c.header === column.columnDef.header);
      if (columnDef && columnDef.visible === false) {
        column.toggleVisibility();
      }
    });
    initializedRef.current = true;
  }
  return (
    <div className="p-2 mb-5">
      <div className="row align-items-center">
        {props.columnVisibility !== false && (
          <div className="col-auto">
            <ColumnSelector
              columns={table.getAllLeafColumns()}
            />
          </div>)}
        {!minimalView && props.globalFilter !== false && (
          <div className="col-sm">
            <DebouncedInput
              value={globalFilter ?? ''}
              onChange={(value) => setGlobalFilter(String(value))}
              className="form-control form-control-sm float-end"
              placeholder="Search all columns..."
            />
          </div>
        )}
      </div>
      <div className="h-2" />
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} >
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort() ?
                                'cursor-pointer select-none' :
                                '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {header.column.getCanSort() ? (
                              <span className="float-end pe-2">
                                {header.column.getIsSorted() ? (
                                  header.column.getIsSorted() === 'desc' ? (
                                    <FontAwesomeIcon icon={faSortDown} />
                                  ) : (
                                    <FontAwesomeIcon icon={faSortUp} />
                                  )
                                ) : (
                                  <FontAwesomeIcon icon={faSort} />
                                )}
                              </span>
                            ) : null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="table-body">
            {table.getRowModel().rows.map((row) => {
              const rowPropsObject = props.rowProps ? props.rowProps(row) : {};
              return (
                <tr key={row.id} {...rowPropsObject}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {table.getFooterGroups().length > 0 && (<tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((footer) => {
                  return (
                    <th key={footer.id} colSpan={footer.colSpan}>
                      {flexRender(
                        footer.column.columnDef.footer,
                        footer.getContext(),
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </tfoot>)}
        </table>
      </div>
      <div className="h-2" />
      {!minimalView && (props.paging != false) && (
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <button
              className="btn btn-light me-1"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </button>
            <button
              className="btn btn-light me-1"
              type='button'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
            <span>
              Page <input
                type="number"
                min="1"
                max={table.getPageCount()}
                value={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                }}
                className="form-control d-inline-block w-auto mx-2 text-center"
                style={{ maxWidth: '5rem' }}
              /> of {table.getPageCount()}
            </span>
            <button
              className="btn btn-light me-1"
              type='button'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
            <button
              className="btn btn-light me-1"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </button>
          </div>
          <div className="d-flex align-items-center">
            <span className="me-2">Show</span>
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
              className="form-select form-select-sm me-2"
              style={{ maxWidth: '6rem' }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            <span>rows</span>
          </div>
        </div>
      )}
    </div>
  );
}


export default BaseTable;
