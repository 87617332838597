import { ClickupClient, Client, NewClient } from "../types/client";
import api from "../utils/api";

export const getClients = async (): Promise<Client[]> => {
  const response = await api.get("/clickup/clients");
  const clickupClients: ClickupClient[] = response.data;
  return clickupClients.map((clickupClient: ClickupClient) => {
    return {
      id: parseInt(clickupClient.clickUpId),
      name: clickupClient.name,
    };
  });
};

export const createClient = async (client: NewClient): Promise<Client> => {
  try {
    const response = await api.post("/clients", client);
    const newClient = {
      id: parseInt(response.data.clickUpId),
      name: response.data.name,
    };
    return newClient;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to create client");
  }
};
