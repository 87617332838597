import React, { Component } from "react";
import MultiSelectDropdown from "../base/multiselect.js";

interface Location {
  id: number;
  name?: string;
}

const LOCATIONS: Location[] = [{
  id: 0,
  name: "Office",
}, {
  id: 1,
  name: "Astro",
}, {
  id: 2,
  name: "ProMaster 1500"
}, {
  id: 3,
  name: "ProMaster City"
}];

interface Props {
  name: string;
  selectedLocation?: Location;
  selectedLocations?: Location[];
  onChangeMultiple?: (name: string, locations: Location[]) => void;
  onChangeSingle?: (name: string, location: Location) => void;
  multiple?: boolean;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  clientId?: number;
  reloadTrigger?: number;
}

interface State {
  locations: Location[];
}

class InventoryLocationDropdown extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      locations: LOCATIONS,
    };
    this.onChange = this.onChange.bind(this);
    this.loadLocations = this.loadLocations.bind(this);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.clientId !== this.props.clientId) {
      this.setState({ locations: [] });
      this.loadLocations();
    }
    if (prevProps.reloadTrigger !== this.props.reloadTrigger) {
      this.setState({ locations: [] });
      this.loadLocations();
    }
  }

  async loadLocations() {
    if (!this.props.clientId) {
      return [];
    }
    const locations = LOCATIONS;
    this.setState({ locations });
    return locations;
  }

  onChange(name: string, locations: Location[]) {
    if (this.props.onChangeMultiple) {
      this.props.onChangeMultiple(name, locations);
    }
    if (this.props.onChangeSingle) {
      this.props.onChangeSingle(name, locations[0]);
    }
  }

  render() {
    return (
      <MultiSelectDropdown<Location>
        name={this.props.name}
        textField="name"
        loadItems={this.loadLocations.bind(this)}
        options={this.state.locations}
        onChange={this.onChange}
        selectedItems={
          this.props.selectedLocations ?? [this.props.selectedLocation]
        }
        required={this.props.required}
        multiple={this.props.multiple}
        disabled={this.props.disabled}
      />
    );
  }
}

export default InventoryLocationDropdown;
