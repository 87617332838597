import React, { Component } from 'react';

interface Props {
    selectedStatus: string;
    onStatusChange: (status: string) => void;
}

interface State {
    // TODO
}

export default class StatusSelect extends Component<Props, State> {
    render() {
        return (
            <div className='form-group'>
                <label className='form-label'>Status</label>
                <select
                    value={this.props.selectedStatus}
                    onChange={(e) => this.props.onStatusChange(e.target.value)}
                    className='form-control'
                >
                    <option id='' value="">All</option>
                    <option id='invoiced' value="invoiced">Invoiced</option>
                    <option id='not-invoiced' value="not-invoiced">Uninvoiced</option>
                    <option id='not-billable' value="not-billable">Unbillable</option>
                </select>
            </div>
        );
    }
}