import _ from 'lodash';
import React, { Component } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import api from '../../utils/api.js';
import LoadingSpinner from '../loadingSpinner.js';

export interface BaseProps<T> {
  show: boolean;
  className?: string;
  onCancel: () => void;
  onSubmit: (item: T) => void;
  initialValues?: Partial<T>;
  extraButton?: React.ReactNode;
  hasCustomFooter?: boolean;
}

export interface BaseState<T> {
  modalTitle: string;
  item: T;
  itemId: number | string;
  loading: boolean;
  error: string;
  isNewItem: boolean;
  url: string;
  validated: boolean;
}

class EditModal<T, P extends BaseProps<T>, S extends BaseState<T>> extends Component<P, S> {
  submit = async (): Promise<T> => {
    const { isNewItem, item } = this.state;
    let response;
    if (isNewItem) {
      response = await api.post(this.state.url, item);
    } else {
      response = await api.put(`${this.state.url}/${this.state.itemId}`, item);
    }
    console.log(`Item ${isNewItem ? 'created' : 'updated'}:`, response.data);
    return response.data;
  };

  loadItem = async (): Promise<T> => {
    const response = await api.get(`${this.state.url}/${this.state.itemId}`);
    return response.data;
  };

  renderFields = (): JSX.Element => {
    return (<div>Form fields not implemented yet.</div>);
  };

  renderFooter = (): JSX.Element => {
    return (<div>Form fields not implemented yet.</div>);
  };

  async componentDidMount() {
    await this.componentDidUpdate({} as P, {} as S);
  }

  async componentDidUpdate(prevProps: P, prevState: S) {
    if (!_.isEqual(prevProps.initialValues, this.props.initialValues)) {
      this.setState({
        item: {
          ...this.state.item,
          ...this.props.initialValues
        }
      });
    }
    if (this.props.show && !prevProps.show && !this.state.isNewItem) {
      this.setState({ loading: true, error: null, validated: false });
      try {
        const item = await this.loadItem();
        this.setState({ item, loading: false });
      } catch (error) {
        console.log(error);
        this.setState({ loading: false, error: error.message });
      }
    }
  }

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    try {
      if (form.checkValidity() === true) {
        const item = await this.submit();
        this.props.onSubmit(item);
      }
    } catch (error) {
      const message = error.response?.data?.error ?? error.message;
      this.setState({ error: message });
    }
    this.setState({ validated: true });
  };

  handleClose = () => {
    this.props.onCancel();
  };

  private handelChangeRecursive = (item: any, key: string, value: any) => {
    const indexOfArray = key.indexOf('[');
    const indexOfDot = key.indexOf('.');
    if (indexOfArray > -1 && (indexOfArray < indexOfDot || indexOfDot == -1)) {
      const arrayName = key.substring(0, indexOfArray);
      const arrayIndex = parseInt(key.substring(indexOfArray + 1, indexOfArray + 2));
      const arrayIndexEnd = key.indexOf(']');
      const subKey = key.substring(arrayIndexEnd + 2);
      const array = [...(item[arrayName] as any[])];
      array[arrayIndex] = this.handelChangeRecursive(array[arrayIndex], subKey, value);
    } else if (indexOfDot > -1) {
      const subKey = key.substring(indexOfDot + 1);
      const subItemName = key.substring(0, indexOfDot);
      item[subItemName] = this.handelChangeRecursive(item[subItemName], subKey, value);
    } else {
      item[key] = value;
    }
    return item;
  };

  handleChange = (event: React.ChangeEvent<any> | { target: { name: string, value: any, type: string; }; }) => {
    const { name, type } = event.target;
    let value = event.target.value;
    value = value === '' ? undefined : value;
    value = value === null ? undefined : value;
    if (type === 'checkbox') {
      value = Boolean(event.target.checked);
    }
    const item = this.handelChangeRecursive({ ...this.state.item }, name, value);
    this.setState({ item });
  };

  handleDropdownChange = (name: string, value: any) => {
    const item = this.handelChangeRecursive({ ...this.state.item }, name, value);

    /* if (name.includes('.') && name.includes('[')) {
      // Built for payable tos which come as payableTos[index].payableTo
      const itemName = name.split('[')[0];
      const subItemName = name.split('.')[1].split('[')[0];
      const index = parseInt(name.split('.')[0].split('[')[1].split(']')[0]);
      const array = [...(item[itemName as keyof T] as T[keyof T][])];
      array[index] = {
        ...array[index],
        [subItemName]: value,
      };
      item[itemName as keyof T] = array as T[keyof T];
    } else if (name.includes('.')) {
      const itemName = name.split('.')[0];
      const subItemName = name.split('.')[1];
      item[itemName as keyof T] = {
        ...item[itemName as keyof T],
        [subItemName]: value,
      };
    } else {
      item[name as keyof T] = value as T[keyof T];
    } */
    this.setState({ item });
  };

  render() {
    const { show, className, extraButton, hasCustomFooter } = this.props;
    const { modalTitle, loading, error, item, validated } = this.state;
    // captue ctrl s
    // document.addEventListener("keydown", this.handleKeyPress, false);
    return (
      <Modal show={show} onHide={this.handleClose} className={className ?? 'modal-xl'}>
        <Form onSubmit={this.handleSubmit} validated={validated} noValidate>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error && <div className="alert alert-danger">{error}</div>}
            {loading && <LoadingSpinner />}
            {item && this.renderFields()}
          </Modal.Body>
          {hasCustomFooter == true ? this.renderFooter() : (
            <Modal.Footer>
              {extraButton}
              <Button variant="primary" type="submit" >
                Save Changes
              </Button>
              <Button variant="secondary" onClick={this.handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          )}
        </Form>
      </Modal>
    );
  }
}

export default EditModal;
