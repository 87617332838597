import { Row } from '@tanstack/react-table';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BaseTable, { ColumnProps } from '../base/baseTable.js';
import { InventoryItem } from './inventoryItemType.js';

const columns: ColumnProps<InventoryItem>[] = [
  {
    header: 'Name',
    accessorKey: 'name',
    filterable: true,
    sortable: true,
  },
  {
    header: 'Description',
    accessorKey: 'description',
    filterable: true,
    sortable: true,
  },
  {
    header: 'Quantity',
    accessorKey: 'quantity',
    filterable: true,
    sortingFn: (a, b) => a.original.quantity - b.original.quantity,
    sortable: true,
  },
  {
    header: 'Purchase Cost',
    accessorKey: 'purchaseCost',
    accessorFn: (row) => numeral(row.purchaseCost).format('$0,0.00'),
    filterable: true,
    sortable: true,
    visible: true,
  },
  {
    header: 'Sales Price',
    accessorKey: 'salesPrice',
    accessorFn: (row) => numeral(row.salesPrice).format('$0,0.00'),
    filterable: true,
    sortable: true,
    visible: true,
  },
  {
    header: 'Date Created',
    accessorKey: 'dateCreated',
    accessorFn: (row) => moment(row.dateCreated).format('YYYY-MM-DD'),
    filterable: true,
    sortable: true,
    visible: false,
  },
  {
    header: 'Date Modified',
    accessorKey: 'dateModified',
    accessorFn: (row) => moment(row.dateModified).format('YYYY-MM-DD'),
    filterable: true,
    sortable: true,
    visible: false,
  },
  {
    header: 'Active',
    accessorKey: 'isActive',
    accessorFn: (row) => row.isActive ? 'Yes' : 'No',
    filterable: true,
    sortable: true,
    visible: false,
  },
  {
    header: 'Total Value',
    accessorFn: (row) => numeral(row.quantity * row.purchaseCost).format('$0,0.00'),
    filterable: true,
    sortable: true,
    sortingFn: (a, b) => a.original.quantity * a.original.purchaseCost - b.original.quantity * b.original.purchaseCost,
    visible: true,
  },
];

interface Props {
  items: InventoryItem[];
}

function InventoryItemsTable({ items }: Props) {
  const navigate = useNavigate();

  const rowProps = (row: Row<InventoryItem>) => {
    return {
      onClick: () => {
        navigate(`/inventoryItems/${row.original.id}`);
      },
      className: 'table-row-hover',
    };
  };

  return <BaseTable
    minimalFormatMinRows={10}
    columnVisibility={true}
    globalFilter={false}
    data={items}
    columns={columns}
    rowProps={rowProps}
    defaultSort={{ id: 'name', desc: true }} />;
}

export default InventoryItemsTable;
