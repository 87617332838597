import {
  faInfinity,
  faPencil,
  faPlay,
  faPoll,
  faStop,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, ListGroup, Row } from "react-bootstrap";
import {
  startClockFromTimeEntry,
  stopClock,
} from "../../../controller/hoursController";
import { TmetricTimeEntriesResponseType } from "../../../types/tmetric";
import { wait } from "../../../utils/util";
import { useTimeDisplayStore } from "../context/TimeDisplayContext";
import { useTotalHoursForTimeEntry } from "../hooks/useTotalHoursForTimeEntry";
import EditTimeEntryModal from "../modals/EditTimeEntryModal";
import { hoursToHm } from "../utils/util";

interface Props {
  timeEntry: TmetricTimeEntriesResponseType;
}

function TimeEntry({ timeEntry }: Props) {
  const [showEditModal, setShowEditModal] = React.useState(false);
  const totalHours = useTotalHoursForTimeEntry(timeEntry);
  const { setLoading, setDateToday, reload } = useTimeDisplayStore();

  async function handleStart() {
    setLoading(true);
    await startClockFromTimeEntry(timeEntry);
    await wait(); // Wait becuz tmetric api is slow
    await wait();
    setDateToday();
    setLoading(false);
    reload();
  }

  async function handleStop() {
    setLoading(true);
    await stopClock();
    await wait();
    reload();
    await wait();
    setLoading(false);
  }

  return (
    <ListGroup.Item className="mb-0">
      <Row>
        <Col xs={4} className="">
          <div className="d-flex justify-content-start align-items-center my-auto h-100">
            <p className="mb-0">{timeEntry.details.description}</p>
          </div>
        </Col>
        <Col xs={4}>
          <div className="d-flex align-items-center bg-info rounded p-1">
            <FontAwesomeIcon icon={faPoll} />
            <p className="ms-2 mb-0">{timeEntry.projectName}</p>
          </div>
        </Col>
        <Col xs={2}>
          <div className="d-flex align-items-center bg-light rounded p-1">
            <FontAwesomeIcon
              icon={faPencil}
              className="me-2 btn btn-light"
              onClick={() => setShowEditModal(true)}
            />
            <p className="mb-0">
              {timeEntry?.startTime?.split("T")[1]?.slice(0, 5)} -{" "}
              {timeEntry.endTime ? (
                timeEntry?.endTime?.split("T")[1]?.slice(0, 5)
              ) : (
                <FontAwesomeIcon className="" icon={faInfinity} />
              )}
            </p>
          </div>
        </Col>
        <Col xs={2}>
          <div className="d-flex justify-content-end align-items-center my-auto h-100">
            <p className="mb-0 fw-bold">{hoursToHm(totalHours)}</p>
            {timeEntry.endTime ? (
              <Button
                variant="success"
                size="sm"
                className="ms-3"
                onClick={handleStart}
              >
                <FontAwesomeIcon icon={faPlay} />
              </Button>
            ) : (
              <Button
                variant="danger"
                size="sm"
                className="ms-3"
                onClick={handleStop}
              >
                <FontAwesomeIcon icon={faStop} />
              </Button>
            )}
          </div>
        </Col>
      </Row>
      {timeEntry && (
        <EditTimeEntryModal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          timeEntry={timeEntry}
        />
      )}
    </ListGroup.Item>
  );
}

export default TimeEntry;
