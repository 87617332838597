import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { forgotPassword as forgotPasswordAPI } from '../controller/userController'; // Renamed to avoid confusion

interface Props {
    passwordResetComplete: () => void;
}

interface State {
    email: string;
    error: string;
    reset: boolean;
}

class ForgotPasswordPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            email: '',
            error: '',
            reset: false,
        };
        this.forgotPassword = this.forgotPassword.bind(this); // Bind the method to the class instance
    }

    validateForm() {
        return this.state.email.length > 0;
    }

    async forgotPassword(e: React.MouseEvent) {
        e.preventDefault(); // Prevent form submission

        if (!this.validateForm()) {
            return;
        }

        forgotPasswordAPI(this.state.email)
            .then(() => {
                this.setState({ reset: true });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ error: error.message });
            });
    }



    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 p-5 text-center'>
                        <h1>You forgot your password again??</h1>
                        <Image
                            src='/shock.png'
                            className='img-fluid'
                            alt='logo'
                            style={{ width: '150px', height: '150px', objectFit: 'contain' }}
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-5">
                        {this.state.reset ? (
                            <Container>
                                <div className="alert alert-success" role="alert">
                                    <h4 className="alert-heading">Password reset!</h4>
                                    <p>Check your email for your new password</p>
                                    <hr />
                                    <p className="mb-0">If you don't receive an email, its probably broken.</p>
                                </div>
                                <button className='btn btn-primary'><FontAwesomeIcon icon={faArrowLeft} onClick={() => this.props.passwordResetComplete()} /> Back to login</button>
                            </Container>

                        ) : (

                            <div className="row">
                                <div className="form-outline mb-4 col-12">
                                    <label className="form-label">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        value={this.state.email}
                                        onChange={(e) => this.setState({ email: e.target.value })}
                                    />
                                </div>
                                <div className="col-12 d-flex">
                                    <button className='btn btn-primary btn-block mb-4' onClick={() => this.props.passwordResetComplete()}><FontAwesomeIcon icon={faArrowLeft} /> Back to login</button>
                                    <button className="btn btn-primary btn-block mb-4 ms-3" onClick={this.forgotPassword}>Send new password</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgotPasswordPage;
